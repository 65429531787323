var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.iShidden === false,
          expression: "iShidden === false",
        },
      ],
    },
    [
      _c("div", { staticClass: "WriteOff" }, [
        _c("div", { staticClass: "pictrue" }, [
          _c("img", {
            attrs: { src: _vm.orderInfo.storeOrderInfoVos[0].info.image },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "num acea-row row-center-wrapper" }, [
          _vm._v("\n      " + _vm._s(_vm.orderInfo.orderId) + "\n      "),
          _c(
            "div",
            {
              staticClass: "views",
              on: {
                click: function ($event) {
                  return _vm.toDetail(_vm.orderInfo)
                },
              },
            },
            [
              _vm._v("\n        " + _vm._s(_vm.$t("ck"))),
              _c("span", { staticClass: "iconfont icon-jiantou views-jian" }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tip" }, [
          _vm._v(_vm._s(_vm.$t("qdyhxcddm"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sure", on: { click: _vm.confirm } }, [
          _vm._v(_vm._s(_vm.$t("qdhx"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sure cancel", on: { click: _vm.cancel } }, [
          _vm._v(_vm._s(_vm.$t("qx"))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "maskModel",
        on: {
          touchmove: function ($event) {
            $event.preventDefault()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }