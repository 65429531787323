"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _codegen = require("@/api/codegen");
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "codegenList",
  data: function data() {
    return {
      constants: this.$constants,
      codeListData: {
        pram: {
          page: 1,
          limit: 10,
          tableName: ''
        },
        data: {
          list: [],
          totalCount: 0
        },
        selectedTables: []
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    handlerSearch: function handlerSearch() {
      this.codeListData.pram.limit = 10;
      this.codeListData.pram.page = 1;
      this.getList(this.codeListData.pram);
    },
    getList: function getList(pram) {
      var _this = this;
      (0, _codegen.getCodegenList)(pram).then(function (res) {
        _this.codeListData.data = res;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.codeListData.pram.limit = val;
      this.getList(this.codeListData.pram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.codeListData.pram.page = val;
      this.getList(this.codeListData.pram);
    },
    handleSelectionChange: function handleSelectionChange(selectedRows) {
      var _this2 = this;
      this.codeListData.selectedTables = [];
      selectedRows.forEach(function (row) {
        _this2.codeListData.selectedTables.push(row.tableName);
      });
    },
    handleGenCode: function handleGenCode() {
      window.open("".concat(_settingMer.default.apiBaseURL, "codegen/code?tables=").concat(this.codeListData.selectedTables.join(",")));
    }
  }
};