"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  options: function options(h, conf, key) {
    var list = [];
    conf.__slot__.options.forEach(function (item) {
      if (conf.__config__.optionType === 'button') {
        list.push(h("el-checkbox-button", {
          "attrs": {
            "label": item.value
          }
        }, [item.label]));
      } else {
        list.push(h("el-checkbox", {
          "attrs": {
            "label": item.value,
            "border": conf.border
          }
        }, [item.label]));
      }
    });
    return list;
  }
};