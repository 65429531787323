//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as api from '@/api/ebcardinfo';
import i18n from '@/i18n/i18n';
export default {
  data: function data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        cardNum: '',
        cardName: '',
        isShow: '',
        createTime: '',
        createBy: '',
        updateTime: '',
        updateBy: '',
        remark: '',
        image: ''
      },
      dataRule: {
        cardNum: [{
          required: true,
          message: i18n.t('qsryhkh'),
          trigger: 'blur'
        }],
        cardName: [{
          required: true,
          message: i18n.t('qsrkmc'),
          trigger: 'blur'
        }],
        /* isShow: [
           { required: true, message: i18n.t('sfzs'), trigger: 'blur' }
         ],*/
        // createTime: [
        //   { required: true, message: '创建时间  为必填项', trigger: 'blur' }
        // ],
        // createBy: [
        //   { required: true, message: '创建人  为必填项', trigger: 'blur' }
        // ],
        // updateTime: [
        //   { required: true, message: '修改时间  为必填项', trigger: 'blur' }
        // ],
        // updateBy: [
        //   { required: true, message: '修改人  为必填项', trigger: 'blur' }
        // ],
        /* remark: [
           { required: true, message: i18n.t('qsrbz'), trigger: 'blur' }
         ],*/
        image: [{
          required: true,
          message: i18n.t('qsctp'),
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    init: function init(id) {
      var _this = this;
      // 初始化表单验证规则
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(function () {
        _this.$refs['dataForm'].resetFields();
        if (_this.dataForm.id) {
          api.ebcardinfoDetailApi(id).then(function (res) {
            _this.dataForm = res;
          });
        }
      });
    },
    // 表单数据提交
    dataSubmit: function dataSubmit() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          api.EbCardInfoCreateApi(_this2.dataForm).then(function (res) {
            _this2.visible = false;
            location.reload();
            // TODO 保存数据
          });
        }
      });
    }
  }
};