"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _distribution = require("@/api/distribution");
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _permission = require("@/utils/permission");
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'Index',
  data: function data() {
    return {
      promoterForm: {},
      loading: true,
      rules: {
        brokerageFuncStatus: [{
          required: true,
          message: '请选择是否启用分销',
          trigger: 'change'
        }],
        storeBrokerageRatio: [{
          required: true,
          message: '请输入一级返佣比例',
          trigger: 'blur'
        }],
        storeBrokerageTwo: [{
          required: true,
          message: '请输入二级返佣比例',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getDetal();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    channelInputLimit: function channelInputLimit(e) {
      var key = e.key;
      // 不允许输入'e'和'.'
      if (key === 'e' || key === '.') {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    getDetal: function getDetal() {
      var _this = this;
      this.loading = true;
      (0, _distribution.configApi)().then(function (res) {
        _this.loading = false;
        _this.promoterForm = res;
        _this.promoterForm.storeBrokerageIsBubble = res.storeBrokerageIsBubble.toString();
        _this.promoterForm.brokerageFuncStatus = res.brokerageFuncStatus.toString();
        _this.promoterForm.brokerageBindind = res.brokerageBindind.toString();
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (selfUtil.Add(_this2.promoterForm.storeBrokerageRatio, _this2.promoterForm.storeBrokerageTwo) > 100) return _this2.$message.warning('返佣比例相加不能超过100%');
          _this2.loading = true;
          (0, _distribution.configUpdateApi)(_this2.promoterForm).then(function (res) {
            _this2.loading = false;
            _this2.$message.success('提交成功');
            // this.$modalSure('提交成功，是否自动下架商户低于此佣金比例的商品').then(() => {
            //   productCheckApi().then(({ message }) => {
            //     this.$message.success(message)
            //   }).catch(({ message }) => {
            //     this.$message.error(message)
            //   })
            // })
          }).catch(function (err) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    })
  }
};