var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["admin:user:group:save", "admin:user:tag:save"],
                      expression:
                        "['admin:user:group:save','admin:user:tag:save']",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onAdd(null)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$route.path.indexOf("group") !== -1
                        ? "添加用户分组"
                        : "添加用户标签"
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", "min-width": "80", prop: "id" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label:
                    _vm.$route.path.indexOf("group") !== -1
                      ? "分组名称"
                      : "标签名称",
                  "min-width": "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$route.path.indexOf("group") !== -1
                                ? row.groupName
                                : row.name
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("cz"),
                  "min-width": "120",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "admin:user:group:update",
                                  "admin:user:tag:update",
                                ],
                                expression:
                                  "['admin:user:group:update','admin:user:tag:update']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onAdd(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("bj")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "admin:user:group:delete",
                                  "admin:user:tag:delete",
                                ],
                                expression:
                                  "['admin:user:group:delete','admin:user:tag:delete']",
                              },
                            ],
                            attrs: { type: "text", size: "small", disable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("sc")))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }