"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wxApi = require("@/api/wxApi");
var _auth = require("@/utils/auth");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'WechatKeyword',
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        keywords: '',
        type: ''
      },
      listLoading: true
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this = this;
      (0, _wxApi.replyStatusApi)({
        id: row.id,
        status: row.status
      }).then(function () {
        _this.$message.success('修改成功');
        _this.getList();
      }).catch(function () {
        row.status = !row.status;
      });
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _wxApi.replyListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _wxApi.replyDeleteApi)({
          id: id
        }).then(function () {
          _this3.$message.success('删除成功');
          _this3.getList();
        });
      });
    }
  }
};