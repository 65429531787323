"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "index",
  data: function data() {
    return {
      listLoadingPink: false,
      dialogVisible: false,
      tableDataPink: {
        data: []
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        dateLimit: '',
        status: '',
        page: 1,
        limit: 20
      },
      fromList: this.$constants.fromList,
      timeVal: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleLook: function handleLook(id) {
      this.dialogVisible = true;
      this.getPink(id);
    },
    getPink: function getPink(id) {
      var _this = this;
      this.listLoadingPink = true;
      (0, _marketing.bargainOrderPinkApi)(id).then(function (res) {
        _this.tableDataPink.data = res;
        _this.listLoadingPink = false;
      }).catch(function () {
        _this.listLoadingPink = false;
      });
    },
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.tableFrom.page = 1;
      this.timeVal = [];
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _marketing.bargainListListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};