export default {
  register: '注册',
  forgetpassword: '忘记密码?',
  Login: '登录',
  a: '主页',
  ab: '个人中心',
  ac: '退出',
  ad: '销售额',
  ae: '今日',
  af: '昨日数据',
  ag: '元',
  fwl: '用户访问量',
  ddl: '订单量',
  dd: '订单',
  xzyh: '新增用户',
  r: '人',
  yhdj: '用户等级',
  djmc: '等级名称',
  qsrdjmc: '请输入等级名称',
  dj: '等级',
  qsrdj: '请输入等级',
  xszk: '享受折扣(%)',
  qsrxszk: '请输入享受折扣',
  jy: '经验',
  qsrjy: '请输入经验',
  tb: '图标',
  qx: '取 消',
  qd: '确 定',
  djbxwsz: '等级必须为数字',
  qsrzk: '请输入折扣',
  jybxwsz: '经验必须为数字值',
  qsctb: '请上传图标',
  qscyhbj: '请上传用户背景',
  bjcg: '编辑成功',
  tjcg: '添加成功',
  djtb: '等级图标',
  zt: '状态',
  kq: '开启',
  gb: '关闭',
  cz: '操作',
  bj: '编辑',
  sc: '删除',
  wxyh: '微信用户',
  xcxyh: '小程序你用户',
  h5yh: 'H5用户',
  deletemsg: '删除吗？删除会导致对应用户等级数据清空，请谨慎操作！',
  sccg: '删除成功',
  xgcg: '修改成功',
  updatemsg: '该操作会导致对应用户等级隐藏，请谨慎操作',
  tjyhdj: '添加用户等级',
  yhss: '用户搜索',
  yhtz: '用户通知',
  yhbh: '用户编号：',
  yhdz: '用户地址：',
  yhbz: '用户备注：',
  qsrxmhsjh: '请输入姓名或手机号',
  hydj: '会员等级：',
  qxz: '请选择',
  sjxz: '时间选择：',
  zdysj: '自定义时间',
  fwqk: '访问情况：',
  qb: '全部',
  scfw: '首次访问',
  sjdfwg: '时间段访问过',
  sjdwfw: '时间段未访问过',
  xb: '性别：',
  wz: '未知',
  nan: '男',
  nv: '女',
  bm: '保密',
  ss: '搜索',
  /*
    cz: '重置',
  */
  zk: '展开',
  sq: '收起',
  plszhy: '批量设置会员',
  sf: '身份：',
  /*
    scfw: '首次访问：',
  */
  jcfw: '近次访问：',
  sjh: '手机号：',
  bq: '标签：',
  dz: '地址：',
  bz: '备注：',
  tx: '头像',
  xm: '姓名',
  ye: '余额',
  caozuo: '操作',
  zhxq: '账户详情',
  gd: '更多',
  xgsjh: '修改手机号',
  xgyhdj: '修改用户等级',
  yhlb: '用户列表',
  sz: '设置',
  yhfz: '用户分组',
  qxzyhfz: '请选择用户分组',
  qxzfz: '请选择分组',
  qxzyhbq: '请选择用户标签',
  yhbq: '用户标签',
  qxzbq: '请选择标签',
  /*
    bj: '编辑',
  */
  jfye: '积分余额',
  xgye: '修改余额',
  zj: '增加',
  js: '减少',
  xgjf: '修改积分',
  jf: '积分',
  yhxq: '用户详情',
  qbyh: '全部用户',
  qsrxgsjh: '请输入修改手机号',
  qsrsjh: '请输入手机号',
  qtxsjh: '请填写手机号',
  /*
    bjcg: '编辑成功',
  */
  qxsr: '取消输入',
  szcg: '设置成功',
  qxzyszdyh: '请选择要设置的用户',
  qxxzyh: '请先选择用户',
  /*
    sccg: '删除成功',
  */
  sjcg: '上架成功',
  xjcg: '下架成功',
  qwpfxg: '请勿频繁更改，以免计算产生混乱！',
  kqjy: '扣除经验',
  zjdd: '总计订单',
  zxfje: '总消费金额',
  bydd: '本月订单',
  byxfje: '本月消费金额',
  ddid: '订单ID',
  shr: '收货人',
  spsl: '商品数量',
  spzj: '商品总价',
  sfje: '实付金额',
  jywcsj: '交易完成时间',
  wzf: '未支付',
  wfh: '未发货',
  dfh: '待收货',
  dpj: '待收货',
  dpl: '待评价',
  jywc: '交易完成',
  /*
    sjxz: '时间选择：',
  */
  ddh: '订单号：',
  qsrddh: '请输入订单号',
  dc: '导出',
  yhysc: '用户已删除',
  /*
    shr: '收货人',
  */
  spxx: '商品信息',
  yxzf: '应需支付',
  sjzf: '实际支付',
  fkpz: '付款凭证',
  zwsc: '暂未上传',
  ddzt: '订单状态',
  yscpzqqrsk: '已上传凭证请确认收款',
  ywc: '已完成',
  xdsj: '下单时间',
  ddxq: '订单详情',
  qrsk: '确认收款',
  fsh: '发送货',
  qrsh: '确认收货',
  ljhx: '立即核销',
  ts: '提示',
  qdyhysdhw: '确定用户已收到货物?',
  qrzf: '确认支付',
  yhzgpzjt: '用户支付凭证截图',
  yhyzfje: '用户应支付金额: ',
  qckyhzfpz: '请查看用户支付凭证，确认无误后点击确认！',
  czjl: '操作记录',
  czsj: '操作时间',
  jjtkyy: '拒绝退款原因',
  tkcl: '退款处理',
  hxddm: '核销订单吗',
  hxcg: '核销成功',
  czcg: '操作成功',
  /*
    sccg: '删除成功',
  */
  nxzdddczyhwscddd: '您选择的的订单存在用户未删除的订单，无法删除用户未删除的订单！',
  /*
    ts: '提示',
  */
  ddbz: '订单备注',
  qsrddbz: '请输入订单备注',
  srbnwk: '输入不能为空',
  /*
    qxsr: '取消输入',
  */
  qrcg: '确定成功',
  bjsjcg: '编辑数据成功',
  ddsl: '订单数量',
  ddje: '订单金额',
  dycg: '打印成功',
  xzlx: '选择类型：',
  sh: '送货',
  fhlx: '发货类型：',
  sdtx: '手动填写',
  dzmddy: '电子面单打印',
  shrxm: '送货人姓名：',
  qsrshrxm: '请输入送货人姓名',
  shrdh: '送货人电话：',
  qsrshrdh: '请输入送货人电话',
  tj: '提交',
  /*
    qtxsjh: '请填写手机号',
  */
  sjhgsbzq: '手机号格式不正确!',
  fshcg: '发送货成功',
  qtxxx: '请填写信息',
  ddxx: '订单信息',
  yhxx: '用户信息',
  yhnc: '用户昵称',
  bddh: '绑定电话',
  ddbh: '订单编号',
  /*
    ddzt: '订单状态',
  */
  spzs: '商品总数',
  /*
    spzj: '商品总价',
  */
  /*
    sjzf: '实际支付',
  */
  cjsj: '创建时间',
  tksj: '退款时间',
  zffs: '支付方式',
  mdmc: '门店名称',
  hxm: '核销码：',
  psxx: '配送信息',
  /*
    shrxm: '送货人姓名：',
  */
  /*
    shrdh: '送货人电话：',
  */
  /*
    yhbz: '用户备注',
  */
  sjbz: '商家备注：',
  /*
    fsh: '发送货',
  */
  kdgs: '快递公司：',
  kddh: '快递单号：',
  qsrkddh: '请输入快递单号',
  zzjzz: '正在加载中',
  sljzgd: '上拉加载更多',
  xxsj: '详细数据',
  rq: '日期',
  dds: '订单数',
  cje: '成交额',
  ck: '查看',
  qdyhxcddm: '确定要核销此订单吗？',
  qdhx: '确定核销',
  dfk: '待付款',
  tk: '退款',
  sjtj: '数据统计',
  jrcje: '今日成交额',
  zrcje: '昨日成交额',
  bycje: '本月成交额',
  jrdds: '今日订单数',
  zrdds: '昨日订单数',
  bydds: '本月订单数',
  fhfs: '发货方式',
  songhr: '送货人',
  txshr: '填写送货人',
  txshdh: '填写送货电话',
  qrtj: '确认提交',
  shdh: '送货电话',
  fh: '发货',
  /*
    sh: '送货',
  */
  wxfh: '无需发货',
  g: '共',
  jsp: '件商品，应支付',
  yf: '邮费 ¥',
  /*
    ddbh: '订单编号：',
  */
  fz: '复制',
  zfzt: '支付状态：',
  /*
    zffs: '支付方式：',
  */
  mjly: '买家留言：',
  zfje: '支付金额：',
  /*
    yf: '运费：',
  */
  sfk: '实付款：',
  psfs: '配送方式：',
  kd: '快递',
  /*
    kddh: '快递单号',
  */
  songhrdh: '送货人电话',
  yjgj: '一键改价',
  ljtk: '立即退款',
  /*
    ddbz: '订单备注',
  */
  qfh: '去发货',
  qhx: '去核销',
  /*
    tk: '退款',
  */
  /*
    ywc: '已完成',
  */
  jjtk: '拒绝退款',
  zwsj: '暂无数据',
  yjjtk: '已拒绝退款',
  tjiaocg: '提交成功',
  jt: 'jt',
  zuotian: '昨天',
  zj7t: '最近7天',
  by: '本月',
  zdy: '自定义',
  zengzhang: '增长',
  zengzhanglv: '增长率',
  zy: '周一',
  ze: '周二',
  zs: '周三',
  zsi: '周四',
  zw: '周五',
  zl: '周六',
  zr: '周日',
  /*
    spxx: '商品信息',
  */
  spxq: '商品详情',
  qtsz: '其他设置',
  spmc: '商品名称:',
  qsrspmc: '请输入商品名称',
  spfl: '商品分类：',
  /*
    qxz: '请选择',
  */
  danwei: '单位：',
  qsrdw: '请输入单位',
  spjj: '商品简介：',
  qsrspjj: '请输入商品简介',
  spfmt: '商品封面图：',
  splbt: '商品轮播图：',
  spgg: '商品规格：',
  dgg: '单规格',
  duogg: '多规格',
  xzgg: '选择规格：',
  tjgg: '添加规格',
  tianjia: '添加',
  guige: '规格：',
  qsrgg: '请输入规格',
  ggz: '规格值：',
  qsrggz: '请输入规格值',
  tjxgg: '添加新规格',
  plsz: '批量设置：',
  tp: '图片',
  qr: '确认',
  /*
    cz: '操作',
  */
  pltj: '批量添加',
  qbsku: '全部sku：',
  zanshi: '展示',
  spsx: '商品属性：',
  paixu: '排序：',
  qsrpx: '请输入排序',
  xnxl: '虚拟销量：',
  qsrxnxl: '请输入虚拟销量',
  syb: '上一步',
  xyb: '下一步',
  tijiao: '提交',
  soujia: '售价',
  vipsoujia: 'VIP售价',
  kc: '库存',
  spbh: '商品编号',
  zliang: '重量（KG）',
  tiji: '体积(m³)',
  /*
    qsrspmc: '请输入商品名称',
  */
  qxzspfl: '请选择商品分类',
  qsrspgjz: '请输入商品关键字',
  /*
    qsrdw: '请输入单位',
  */
  qscspt: '请上传商品图',
  qscsplbt: '请上传商品轮播图',
  qxzspgg: '请选择商品规格',
  qxzsx: '请选择属性',
  qtjwzdgg: '请添加完整的规格！',
  qtjsx: '请添加属性',
  zdxz: '最多选择10张图片！',
  qtxwzspxx: '请填写完整商品信息！',
  qtxdggsx: '请填写多规格属性！',
  sfrm: '是否热卖',
  ggss: '规格搜索：',
  qsrspgg: '请输入商品规格',
  tjspgg: '添加商品规格',
  plsc: '批量删除',
  ggmc: '规格名称',
  /*  spgg: '商品规格',
    spsx: '商品属性',
    sccg: '删除成功',
    qxzspgg: '请选择商品规格',
    spfl: '商品分类：',*/
  spss: '商品搜索：',
  qsrspmcgjz: '请输入商品名称，关键字，商品ID',
  tjsp: '添加商品',
  /*
    dc: '导出',
  */
  /*
    spfl: '商品分类：',
  */
  scj: '市场价：',
  cbj: '成本价：',
  /*
    sc: '收藏：',
  */
  /*
    xnxl: '虚拟销量：',
  */
  spt: '商品图',
  /*
    spmc: '商品名称',
  */
  spsj: '商品售价',
  vipsj: 'VIP售价',
  xl: '销量',
  /*
    kc: '库存',
  */
  px: '排序',
  tjsj: '添加时间',
  sj: '上架',
  xj: '下架',
  xq: '详情',
  hfsp: '恢复商品',
  /*
    czcg: '操作成功',
  */
  scid: '删除 id 为',
  wzfl: '文章分类：',
  qxzwzfl: '请选择文章分类',
  gjc: '关键词：',
  qsrgjc: '请输入关键词',
  tjwz: '添加文章',
  bt: '标题',
  /*
    wzfl: '文章分类',
  */
  lll: '浏览量',
  zz: '作者',
  gxsj: '更新时间',
  wzjj: '文章简介',
  /*
    bj: '编辑',
  */
  qdscdqsj: '确定删除当前数据',
  sanchu: '删除',
  /*
    bt: '标题',
  */
  zuozhe: '作者',
  /*
    wzfl: '文章分类',
  */
  /*
    qxz: '请选择',
  */
  twfm: '图文封面',
  /*
    wzjj: '文章简介',
  */
  wznr: '文章内容',
  isb: '是否Banner',
  ishot: '是否热门',
  bc: '保存',
  qtxbt: '请填写标题',
  /*
    fh: '返回',
  */
  gifly: 'gif来源',
  ym: '页面',
  nmyqx: '你没有权限去该页面',
  ysmwt: '如有不满请联系你领导',
  hznky: '或者你可以去:',
  hsy: '回首页',
  sbkk: '随便看看',
  dwkt: '点我看图',
  qjcqjc: '请检查您输入的URL是否正确，或单击下面的按钮返回主页.',
  fhkzt: '返回控制台',
  hygl: '会员管理',
  xtsz: '系统设置',
  sp: '商品',
  ddgl: '订单管理',
  skzh: '收款账户',
  yhtj: '用户统计',
  rsr: '人数（人）',
  ddtj: '订单统计',
  sst: '30天',
  zou: '周',
  yue: '月',
  nian: '年',
  ddqs: '订单趋势',
  je: '金额',
  sl: '数量',
  /*
    ddje: '订单金额',
  */
  /*
    dds: '订单数',
  */
  szje: '上周金额',
  bzje: '本周金额',
  szdds: '上周订单数',
  bzdds: '本周订单数',
  yhm: '用户名',
  mima: '密码',
  yzm: '验证码',
  dl: '登录',
  mmws: '密码位数为6-12位',
  qsryhm: '请输入用户名',
  qsrmm: '请输入密码',
  qsrzqdyzm: '请输入正确的验证码',
  yhkh: '银行卡号',
  yhkmc: '卡名称',
  sfzs: '是否展示',
  /*
    bz: '备注',
  */
  tpdz: '图片地址',
  qsryhkh: '请输入银行卡号',
  qsrkmc: '请输入卡名称',
  qsrbz: '请输入备注',
  qsctp: '请上传图片',
  cxcs: '查询参数',
  cx: '查询',
  xzsj: '新增数据',
  /*
    plsc: '批量删除',
  */
  /*
    cjsj: '创建时间',
  */
  cjr: '创建人',
  xgsj: '修改时间',
  xgr: '修改人',
  jrhsz: '加入回收站'
};