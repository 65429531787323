"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _authInformation = require("@/api/authInformation");
var _systemConfig = require("@/api/systemConfig");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "index",
  data: function data() {
    return {
      form: {
        company_name: '',
        domain_name: '',
        order_id: '',
        captcha: '',
        phone: '',
        label: 22
      },
      captchs: 'http://authorize.crmeb.net/api/captchs/',
      rules: {
        company_name: [{
          required: true,
          message: '请填写您的企业名称',
          trigger: 'blur'
        }],
        domain_name: [{
          required: true,
          message: '请输入域名，格式：baidu.com',
          trigger: 'blur'
        }],
        order_id: [{
          required: true,
          message: '请输入您购买的源码订单号',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入负责人电话',
          trigger: 'blur'
        }],
        captcha: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getCaptcha();
  },
  methods: {
    getCaptcha: function getCaptcha() {
      this.captchs = this.captchs + Date.parse(new Date());
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _authInformation.authCertSubmit)(_this.form).then(function (res) {
            if (res.status === 200) {
              _this.$modal.msgSuccess(res.msg);
              (0, _systemConfig.configSaveUniq)({
                key: 'authHost',
                value: _this.form.domain_name
              });
            } else {
              _this.$modal.msgError(res.msg);
            }
          }).catch(function (res) {
            _this.getCaptcha();
            return _this.$modal.msgError(res.msg);
          });
        } else {
          return false;
        }
      });
    }),
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};