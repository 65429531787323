var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "components-container" },
          [
            _c(
              "el-form",
              {
                ref: "pram",
                attrs: { "label-width": "150px", model: _vm.pram },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("bt"),
                      prop: "title",
                      rules: [
                        {
                          required: true,
                          message: "请填写标题",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: _vm.$t("bt"), maxlength: "100" },
                      model: {
                        value: _vm.pram.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "title", $$v)
                        },
                        expression: "pram.title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("zuozhe"),
                      prop: "author",
                      rules: [
                        {
                          required: true,
                          message: "请填作者",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: _vm.$t("zuozhe"), maxlength: "20" },
                      model: {
                        value: _vm.pram.author,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "author", $$v)
                        },
                        expression: "pram.author",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("wzfl"),
                      rules: [
                        {
                          required: true,
                          message: "请选择分类",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: _vm.$t("qxz") },
                        model: {
                          value: _vm.pram.cid,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "cid", $$v)
                          },
                          expression: "pram.cid",
                        },
                      },
                      _vm._l(_vm.categoryTreeData, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("twfm"),
                      prop: "imageInput",
                      rules: [
                        {
                          required: true,
                          message: "请上传图文封面",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upLoadPicBox",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap("1")
                          },
                        },
                      },
                      [
                        _vm.pram.imageInput
                          ? _c("div", { staticClass: "pictrue" }, [
                              _c("img", {
                                attrs: { src: _vm.pram.imageInput },
                              }),
                            ])
                          : _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("wzjj"),
                      prop: "synopsis",
                      rules: [
                        {
                          required: true,
                          message: "请填写文章简介",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        maxlength: "100",
                        type: "textarea",
                        rows: 2,
                        resize: "none",
                        placeholder: _vm.$t("wzjj"),
                      },
                      model: {
                        value: _vm.pram.synopsis,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "synopsis", $$v)
                        },
                        expression: "pram.synopsis",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("wzjj"),
                      prop: "content",
                      rules: [
                        {
                          required: true,
                          message: "请填写文章内容",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("Tinymce", {
                      model: {
                        value: _vm.pram.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "content", $$v)
                        },
                        expression: "pram.content",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("isb") } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.pram.isBanner,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "isBanner", $$v)
                        },
                        expression: "pram.isBanner",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("ishot") } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.pram.isHot,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "isHot", $$v)
                        },
                        expression: "pram.isHot",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["admin:article:update"],
                            expression: "['admin:article:update']",
                          },
                        ],
                        attrs: { type: "primary", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.handerSubmit("pram")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("bc")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }