"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = loadBeautifier;
var _loadScript = _interopRequireDefault(require("./loadScript"));
var _elementUi = _interopRequireDefault(require("element-ui"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var beautifierObj;
function loadBeautifier(cb) {
  if (beautifierObj) {
    cb(beautifierObj);
    return;
  }
  var loading = _elementUi.default.Loading.service({
    fullscreen: true,
    lock: true,
    text: '格式化资源加载中...',
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, 0.5)'
  });
  (0, _loadScript.default)('https://cdn.bootcss.com/js-beautify/1.10.2/beautifier.min.js', function () {
    loading.close();
    // eslint-disable-next-line no-undef
    beautifierObj = beautifier;
    cb(beautifierObj);
  });
}