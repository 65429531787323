"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cssStyle = cssStyle;
exports.dialogWrapper = dialogWrapper;
exports.makeUpHtml = makeUpHtml;
exports.vueScript = vueScript;
exports.vueTemplate = vueTemplate;
var _ruleTrigger = _interopRequireDefault(require("./ruleTrigger"));
var _auth = require("@/utils/auth");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/* eslint-disable max-len */

var confGlobal;
var someSpanIsNot24;
function dialogWrapper(str) {
  return "<el-dialog v-bind=\"$attrs\" v-on=\"$listeners\" @open=\"onOpen\"  @close=\"onClose\" title=\"Dialog Titile\">\n    ".concat(str, "\n    <div slot=\"footer\">\n      <el-button @click=\"close\">\u53D6\u6D88</el-button>\n      <el-button type=\"primary\" @click=\"handelConfirm\">\u786E\u5B9A</el-button>\n    </div>\n  </el-dialog>");
}
function vueTemplate(str) {
  return "<template>\n    <div>\n      ".concat(str, "\n    </div>\n  </template>");
}
function vueScript(str) {
  return "<script>\n    ".concat(str, "\n  </script>");
}
function cssStyle(cssStr) {
  return "<style>\n    ".concat(cssStr, "\n  </style>");
}
function buildFormTemplate(scheme, child, type) {
  var labelPosition = '';
  if (scheme.labelPosition !== 'right') {
    labelPosition = "label-position=\"".concat(scheme.labelPosition, "\"");
  }
  var disabled = scheme.disabled ? ":disabled=\"".concat(scheme.disabled, "\"") : '';
  var str = "<el-form ref=\"".concat(scheme.formRef, "\" :model=\"").concat(scheme.formModel, "\" :rules=\"").concat(scheme.formRules, "\" size=\"").concat(scheme.size, "\" ").concat(disabled, " label-width=\"").concat(scheme.labelWidth, "px\" ").concat(labelPosition, ">\n      ").concat(child, "\n      ").concat(buildFromBtns(scheme, type), "\n    </el-form>");
  if (someSpanIsNot24) {
    str = "<el-row :gutter=\"".concat(scheme.gutter, "\">\n        ").concat(str, "\n      </el-row>");
  }
  return str;
}
function buildFromBtns(scheme, type) {
  var str = '';
  if (scheme.formBtns && type === 'file') {
    str = "<el-form-item size=\"large\">\n          <el-button type=\"primary\" @click=\"submitForm\">\u63D0\u4EA4</el-button>\n          <el-button @click=\"resetForm\">\u53D6\u6D88</el-button>\n        </el-form-item>";
    if (someSpanIsNot24) {
      str = "<el-col :span=\"24\">\n          ".concat(str, "\n        </el-col>");
    }
  }
  return str;
}

// span不为24的用el-col包裹
function colWrapper(scheme, str) {
  if (someSpanIsNot24 || scheme.__config__.span !== 24) {
    return "<el-col :span=\"".concat(scheme.__config__.span, "\">\n      ").concat(str, "\n    </el-col>");
  }
  return str;
}
var layouts = {
  colFormItem: function colFormItem(scheme) {
    var config = scheme.__config__;
    var labelWidth = '';
    var label = "label=\"".concat(config.label, "\"");
    if (config.labelWidth && config.labelWidth !== confGlobal.labelWidth) {
      labelWidth = "label-width=\"".concat(config.labelWidth, "px\"");
    }
    if (config.showLabel === false) {
      labelWidth = 'label-width="0"';
      label = '';
    }
    var required = !_ruleTrigger.default[config.tag] && config.required ? 'required' : '';
    var tagDom = tags[config.tag] ? tags[config.tag](scheme) : null;
    var str = "<el-form-item ".concat(labelWidth, " ").concat(label, " prop=\"").concat(scheme.__vModel__, "\" ").concat(required, ">\n        ").concat(tagDom, "\n      </el-form-item>");
    str = colWrapper(scheme, str);
    return str;
  },
  rowFormItem: function rowFormItem(scheme) {
    var config = scheme.__config__;
    var type = scheme.type === 'default' ? '' : "type=\"".concat(scheme.type, "\"");
    var justify = scheme.type === 'default' ? '' : "justify=\"".concat(scheme.justify, "\"");
    var align = scheme.type === 'default' ? '' : "align=\"".concat(scheme.align, "\"");
    var gutter = scheme.gutter ? ":gutter=\"".concat(scheme.gutter, "\"") : '';
    var children = config.children.map(function (el) {
      return layouts[el.__config__.layout](el);
    });
    var str = "<el-row ".concat(type, " ").concat(justify, " ").concat(align, " ").concat(gutter, ">\n      ").concat(children.join('\n'), "\n    </el-row>");
    str = colWrapper(scheme, str);
    return str;
  }
};
var tags = {
  'el-button': function elButton(el) {
    var _attrBuilder = attrBuilder(el),
      tag = _attrBuilder.tag,
      disabled = _attrBuilder.disabled;
    var type = el.type ? "type=\"".concat(el.type, "\"") : '';
    var icon = el.icon ? "icon=\"".concat(el.icon, "\"") : '';
    var round = el.round ? 'round' : '';
    var size = el.size ? "size=\"".concat(el.size, "\"") : '';
    var plain = el.plain ? 'plain' : '';
    var circle = el.circle ? 'circle' : '';
    var child = buildElButtonChild(el);
    if (child) child = "\n".concat(child, "\n"); // 换行
    return "<".concat(tag, " ").concat(type, " ").concat(icon, " ").concat(round, " ").concat(size, " ").concat(plain, " ").concat(disabled, " ").concat(circle, ">").concat(child, "</").concat(tag, ">");
  },
  'el-input': function elInput(el) {
    var _attrBuilder2 = attrBuilder(el),
      tag = _attrBuilder2.tag,
      disabled = _attrBuilder2.disabled,
      vModel = _attrBuilder2.vModel,
      clearable = _attrBuilder2.clearable,
      placeholder = _attrBuilder2.placeholder,
      width = _attrBuilder2.width;
    var maxlength = el.maxlength ? ":maxlength=\"".concat(el.maxlength, "\"") : '';
    var showWordLimit = el['show-word-limit'] ? 'show-word-limit' : '';
    var readonly = el.readonly ? 'readonly' : '';
    var prefixIcon = el['prefix-icon'] ? "prefix-icon='".concat(el['prefix-icon'], "'") : '';
    var suffixIcon = el['suffix-icon'] ? "suffix-icon='".concat(el['suffix-icon'], "'") : '';
    var showPassword = el['show-password'] ? 'show-password' : '';
    var type = el.type ? "type=\"".concat(el.type, "\"") : '';
    var autosize = el.autosize && el.autosize.minRows ? ":autosize=\"{minRows: ".concat(el.autosize.minRows, ", maxRows: ").concat(el.autosize.maxRows, "}\"") : '';
    var child = buildElInputChild(el);
    if (child) child = "\n".concat(child, "\n"); // 换行
    return "<".concat(tag, " ").concat(vModel, " ").concat(type, " ").concat(placeholder, " ").concat(maxlength, " ").concat(showWordLimit, " ").concat(readonly, " ").concat(disabled, " ").concat(clearable, " ").concat(prefixIcon, " ").concat(suffixIcon, " ").concat(showPassword, " ").concat(autosize, " ").concat(width, ">").concat(child, "</").concat(tag, ">");
  },
  'el-input-number': function elInputNumber(el) {
    var _attrBuilder3 = attrBuilder(el),
      tag = _attrBuilder3.tag,
      disabled = _attrBuilder3.disabled,
      vModel = _attrBuilder3.vModel,
      placeholder = _attrBuilder3.placeholder;
    var controlsPosition = el['controls-position'] ? "controls-position=".concat(el['controls-position']) : '';
    var min = el.min ? ":min='".concat(el.min, "'") : '';
    var max = el.max ? ":max='".concat(el.max, "'") : '';
    var step = el.step ? ":step='".concat(el.step, "'") : '';
    var stepStrictly = el['step-strictly'] ? 'step-strictly' : '';
    var precision = el.precision ? ":precision='".concat(el.precision, "'") : '';
    return "<".concat(tag, " ").concat(vModel, " ").concat(placeholder, " ").concat(step, " ").concat(stepStrictly, " ").concat(precision, " ").concat(controlsPosition, " ").concat(min, " ").concat(max, " ").concat(disabled, "></").concat(tag, ">");
  },
  'el-select': function elSelect(el) {
    var _attrBuilder4 = attrBuilder(el),
      tag = _attrBuilder4.tag,
      disabled = _attrBuilder4.disabled,
      vModel = _attrBuilder4.vModel,
      clearable = _attrBuilder4.clearable,
      placeholder = _attrBuilder4.placeholder,
      width = _attrBuilder4.width;
    var filterable = el.filterable ? 'filterable' : '';
    var multiple = el.multiple ? 'multiple' : '';
    var child = buildElSelectChild(el);
    if (child) child = "\n".concat(child, "\n"); // 换行
    return "<".concat(tag, " ").concat(vModel, " ").concat(placeholder, " ").concat(disabled, " ").concat(multiple, " ").concat(filterable, " ").concat(clearable, " ").concat(width, ">").concat(child, "</").concat(tag, ">");
  },
  'el-radio-group': function elRadioGroup(el) {
    var _attrBuilder5 = attrBuilder(el),
      tag = _attrBuilder5.tag,
      disabled = _attrBuilder5.disabled,
      vModel = _attrBuilder5.vModel;
    var size = "size=\"".concat(el.size, "\"");
    var child = buildElRadioGroupChild(el);
    if (child) child = "\n".concat(child, "\n"); // 换行
    return "<".concat(tag, " ").concat(vModel, " ").concat(size, " ").concat(disabled, ">").concat(child, "</").concat(tag, ">");
  },
  'el-checkbox-group': function elCheckboxGroup(el) {
    var _attrBuilder6 = attrBuilder(el),
      tag = _attrBuilder6.tag,
      disabled = _attrBuilder6.disabled,
      vModel = _attrBuilder6.vModel;
    var size = "size=\"".concat(el.size, "\"");
    var min = el.min ? ":min=\"".concat(el.min, "\"") : '';
    var max = el.max ? ":max=\"".concat(el.max, "\"") : '';
    var child = buildElCheckboxGroupChild(el);
    if (child) child = "\n".concat(child, "\n"); // 换行
    return "<".concat(tag, " ").concat(vModel, " ").concat(min, " ").concat(max, " ").concat(size, " ").concat(disabled, ">").concat(child, "</").concat(tag, ">");
  },
  'el-switch': function elSwitch(el) {
    var _attrBuilder7 = attrBuilder(el),
      tag = _attrBuilder7.tag,
      disabled = _attrBuilder7.disabled,
      vModel = _attrBuilder7.vModel;
    var activeText = el['active-text'] ? "active-text=\"".concat(el['active-text'], "\"") : '';
    var inactiveText = el['inactive-text'] ? "inactive-text=\"".concat(el['inactive-text'], "\"") : '';
    var activeColor = el['active-color'] ? "active-color=\"".concat(el['active-color'], "\"") : '';
    var inactiveColor = el['inactive-color'] ? "inactive-color=\"".concat(el['inactive-color'], "\"") : '';
    var activeValue = el['active-value'] !== true ? ":active-value='".concat(JSON.stringify(el['active-value']), "'") : '';
    var inactiveValue = el['inactive-value'] !== false ? ":inactive-value='".concat(JSON.stringify(el['inactive-value']), "'") : '';
    return "<".concat(tag, " ").concat(vModel, " ").concat(activeText, " ").concat(inactiveText, " ").concat(activeColor, " ").concat(inactiveColor, " ").concat(activeValue, " ").concat(inactiveValue, " ").concat(disabled, "></").concat(tag, ">");
  },
  'el-cascader': function elCascader(el) {
    var _attrBuilder8 = attrBuilder(el),
      tag = _attrBuilder8.tag,
      disabled = _attrBuilder8.disabled,
      vModel = _attrBuilder8.vModel,
      clearable = _attrBuilder8.clearable,
      placeholder = _attrBuilder8.placeholder,
      width = _attrBuilder8.width;
    var options = el.options ? ":options=\"".concat(el.__vModel__, "Options\"") : '';
    var props = el.props ? ":props=\"".concat(el.__vModel__, "Props\"") : '';
    var showAllLevels = el['show-all-levels'] ? '' : ':show-all-levels="false"';
    var filterable = el.filterable ? 'filterable' : '';
    var separator = el.separator === '/' ? '' : "separator=\"".concat(el.separator, "\"");
    return "<".concat(tag, " ").concat(vModel, " ").concat(options, " ").concat(props, " ").concat(width, " ").concat(showAllLevels, " ").concat(placeholder, " ").concat(separator, " ").concat(filterable, " ").concat(clearable, " ").concat(disabled, "></").concat(tag, ">");
  },
  'el-slider': function elSlider(el) {
    var _attrBuilder9 = attrBuilder(el),
      tag = _attrBuilder9.tag,
      disabled = _attrBuilder9.disabled,
      vModel = _attrBuilder9.vModel;
    var min = el.min ? ":min='".concat(el.min, "'") : '';
    var max = el.max ? ":max='".concat(el.max, "'") : '';
    var step = el.step ? ":step='".concat(el.step, "'") : '';
    var range = el.range ? 'range' : '';
    var showStops = el['show-stops'] ? ":show-stops=\"".concat(el['show-stops'], "\"") : '';
    return "<".concat(tag, " ").concat(min, " ").concat(max, " ").concat(step, " ").concat(vModel, " ").concat(range, " ").concat(showStops, " ").concat(disabled, "></").concat(tag, ">");
  },
  'el-time-picker': function elTimePicker(el) {
    var _attrBuilder10 = attrBuilder(el),
      tag = _attrBuilder10.tag,
      disabled = _attrBuilder10.disabled,
      vModel = _attrBuilder10.vModel,
      clearable = _attrBuilder10.clearable,
      placeholder = _attrBuilder10.placeholder,
      width = _attrBuilder10.width;
    var startPlaceholder = el['start-placeholder'] ? "start-placeholder=\"".concat(el['start-placeholder'], "\"") : '';
    var endPlaceholder = el['end-placeholder'] ? "end-placeholder=\"".concat(el['end-placeholder'], "\"") : '';
    var rangeSeparator = el['range-separator'] ? "range-separator=\"".concat(el['range-separator'], "\"") : '';
    var isRange = el['is-range'] ? 'is-range' : '';
    var format = el.format ? "format=\"".concat(el.format, "\"") : '';
    var valueFormat = el['value-format'] ? "value-format=\"".concat(el['value-format'], "\"") : '';
    var pickerOptions = el['picker-options'] ? ":picker-options='".concat(JSON.stringify(el['picker-options']), "'") : '';
    return "<".concat(tag, " ").concat(vModel, " ").concat(isRange, " ").concat(format, " ").concat(valueFormat, " ").concat(pickerOptions, " ").concat(width, " ").concat(placeholder, " ").concat(startPlaceholder, " ").concat(endPlaceholder, " ").concat(rangeSeparator, " ").concat(clearable, " ").concat(disabled, "></").concat(tag, ">");
  },
  'el-date-picker': function elDatePicker(el) {
    var _attrBuilder11 = attrBuilder(el),
      tag = _attrBuilder11.tag,
      disabled = _attrBuilder11.disabled,
      vModel = _attrBuilder11.vModel,
      clearable = _attrBuilder11.clearable,
      placeholder = _attrBuilder11.placeholder,
      width = _attrBuilder11.width;
    var startPlaceholder = el['start-placeholder'] ? "start-placeholder=\"".concat(el['start-placeholder'], "\"") : '';
    var endPlaceholder = el['end-placeholder'] ? "end-placeholder=\"".concat(el['end-placeholder'], "\"") : '';
    var rangeSeparator = el['range-separator'] ? "range-separator=\"".concat(el['range-separator'], "\"") : '';
    var format = el.format ? "format=\"".concat(el.format, "\"") : '';
    var valueFormat = el['value-format'] ? "value-format=\"".concat(el['value-format'], "\"") : '';
    var type = el.type === 'date' ? '' : "type=\"".concat(el.type, "\"");
    var readonly = el.readonly ? 'readonly' : '';
    return "<".concat(tag, " ").concat(type, " ").concat(vModel, " ").concat(format, " ").concat(valueFormat, " ").concat(width, " ").concat(placeholder, " ").concat(startPlaceholder, " ").concat(endPlaceholder, " ").concat(rangeSeparator, " ").concat(clearable, " ").concat(readonly, " ").concat(disabled, "></").concat(tag, ">");
  },
  'el-rate': function elRate(el) {
    var _attrBuilder12 = attrBuilder(el),
      tag = _attrBuilder12.tag,
      disabled = _attrBuilder12.disabled,
      vModel = _attrBuilder12.vModel;
    var max = el.max ? ":max='".concat(el.max, "'") : '';
    var allowHalf = el['allow-half'] ? 'allow-half' : '';
    var showText = el['show-text'] ? 'show-text' : '';
    var showScore = el['show-score'] ? 'show-score' : '';
    return "<".concat(tag, " ").concat(vModel, " ").concat(max, " ").concat(allowHalf, " ").concat(showText, " ").concat(showScore, " ").concat(disabled, "></").concat(tag, ">");
  },
  'el-color-picker': function elColorPicker(el) {
    var _attrBuilder13 = attrBuilder(el),
      tag = _attrBuilder13.tag,
      disabled = _attrBuilder13.disabled,
      vModel = _attrBuilder13.vModel;
    var size = "size=\"".concat(el.size, "\"");
    var showAlpha = el['show-alpha'] ? 'show-alpha' : '';
    var colorFormat = el['color-format'] ? "color-format=\"".concat(el['color-format'], "\"") : '';
    return "<".concat(tag, " ").concat(vModel, " ").concat(size, " ").concat(showAlpha, " ").concat(colorFormat, " ").concat(disabled, "></").concat(tag, ">");
  },
  'el-upload': function elUpload(el) {
    var tag = el.__config__.tag;
    var disabled = el.disabled ? ':disabled=\'true\'' : '';
    var action = el.action ? ":action=\"".concat(el.__vModel__, "Action\"") : '';
    var multiple = el.multiple ? 'multiple' : '';
    var listType = el['list-type'] !== 'text' ? "list-type=\"".concat(el['list-type'], "\"") : '';
    var accept = el.accept ? "accept=\"".concat(el.accept, "\"") : '';
    var name = el.name !== 'file' ? "name=\"".concat(el.name, "\"") : '';
    var autoUpload = el['auto-upload'] === false ? ':auto-upload="false"' : '';
    var beforeUpload = ":before-upload=\"".concat(el.__vModel__, "BeforeUpload\"");
    var fileList = ":file-list=\"".concat(el.__vModel__, "fileList\"");
    var ref = "ref=\"".concat(el.__vModel__, "\"");
    var headers = {
      'Authori-zation': (0, _auth.getToken)()
    };
    var data = el.data ? 'data' : '';
    var child = buildElUploadChild(el);
    if (child) child = "\n".concat(child, "\n"); // 换行
    return "<".concat(tag, " ").concat(headers, " ").concat(data, " ").concat(ref, " ").concat(fileList, " ").concat(action, " ").concat(autoUpload, " ").concat(multiple, " ").concat(beforeUpload, " ").concat(listType, " ").concat(accept, " ").concat(name, " ").concat(disabled, ">").concat(child, "</").concat(tag, ">");
  },
  'self-upload': function selfUpload(el) {
    var _attrBuilder14 = attrBuilder(el),
      tag = _attrBuilder14.tag,
      vModel = _attrBuilder14.vModel;
    var height = el.height ? ":height=\"".concat(el.height, "\"") : '';
    var multiple = el.multiple ? 'multiple' : '';
    var branding = el.branding ? ":branding=\"".concat(el.branding, "\"") : '';
    return "<".concat(tag, " ").concat(vModel, " ").concat(height, " ").concat(branding, " ").concat(multiple, "></").concat(tag, ">");
  },
  'ueditor-from': function ueditorFrom(el) {
    var _attrBuilder15 = attrBuilder(el),
      tag = _attrBuilder15.tag,
      vModel = _attrBuilder15.vModel;
    var height = el.height ? ":height=\"".concat(el.height, "\"") : '';
    return "<".concat(tag, " ").concat(vModel).concat(height, " >");
  },
  'upload-file': function uploadFile(el) {
    var _attrBuilder16 = attrBuilder(el),
      tag = _attrBuilder16.tag,
      vModel = _attrBuilder16.vModel;
    var height = el.height ? ":height=\"".concat(el.height, "\"") : '';
    return "<".concat(tag, " ").concat(vModel).concat(height, " >");
  },
  'time-select': function timeSelect(el) {
    var _attrBuilder17 = attrBuilder(el),
      tag = _attrBuilder17.tag,
      vModel = _attrBuilder17.vModel;
    var height = el.height ? ":height=\"".concat(el.height, "\"") : '';
    return "<".concat(tag, " ").concat(vModel).concat(height, " >");
  },
  tinymce: function tinymce(el) {
    var _attrBuilder18 = attrBuilder(el),
      tag = _attrBuilder18.tag,
      vModel = _attrBuilder18.vModel;
    var branding = el.branding ? ":branding=\"".concat(el.branding, "\"") : '';
    return "<".concat(tag, " ").concat(vModel, " ").concat(branding, "></").concat(tag, ">");
  }
};
function attrBuilder(el) {
  return {
    tag: el.__config__.tag,
    vModel: "v-model=\"".concat(confGlobal.formModel, ".").concat(el.__vModel__, "\""),
    clearable: el.clearable ? 'clearable' : '',
    placeholder: el.placeholder ? "placeholder=\"".concat(el.placeholder, "\"") : '',
    width: el.style && el.style.width ? ':style="{width: \'100%\'}"' : '',
    disabled: el.disabled ? ':disabled=\'true\'' : ''
  };
}

// el-buttin 子级
function buildElButtonChild(scheme) {
  var children = [];
  var slot = scheme.__slot__ || {};
  if (slot.default) {
    children.push(slot.default);
  }
  return children.join('\n');
}

// el-input 子级
function buildElInputChild(scheme) {
  var children = [];
  var slot = scheme.__slot__;
  if (slot && slot.prepend) {
    children.push("<template slot=\"prepend\">".concat(slot.prepend, "</template>"));
  }
  if (slot && slot.append) {
    children.push("<template slot=\"append\">".concat(slot.append, "</template>"));
  }
  return children.join('\n');
}

// el-select 子级
function buildElSelectChild(scheme) {
  var children = [];
  var slot = scheme.__slot__;
  if (slot && slot.options && slot.options.length) {
    children.push("<el-option v-for=\"(item, index) in ".concat(scheme.__vModel__, "Options\" :key=\"index\" :label=\"item.label\" :value=\"item.value\" :disabled=\"item.disabled\"></el-option>"));
  }
  return children.join('\n');
}

// el-radio-group 子级
function buildElRadioGroupChild(scheme) {
  var children = [];
  var slot = scheme.__slot__;
  var config = scheme.__config__;
  if (slot && slot.options && slot.options.length) {
    var tag = config.optionType === 'button' ? 'el-radio-button' : 'el-radio';
    var border = config.border ? 'border' : '';
    children.push("<".concat(tag, " v-for=\"(item, index) in ").concat(scheme.__vModel__, "Options\" :key=\"index\" :label=\"item.value\" :disabled=\"item.disabled\" ").concat(border, ">{{item.label}}</").concat(tag, ">"));
  }
  return children.join('\n');
}

// el-checkbox-group 子级
function buildElCheckboxGroupChild(scheme) {
  var children = [];
  var slot = scheme.__slot__;
  var config = scheme.__config__;
  if (slot && slot.options && slot.options.length) {
    var tag = config.optionType === 'button' ? 'el-checkbox-button' : 'el-checkbox';
    var border = config.border ? 'border' : '';
    children.push("<".concat(tag, " v-for=\"(item, index) in ").concat(scheme.__vModel__, "Options\" :key=\"index\" :label=\"item.value\" :disabled=\"item.disabled\" ").concat(border, ">{{item.label}}</").concat(tag, ">"));
  }
  return children.join('\n');
}

// el-upload 子级
function buildElUploadChild(scheme) {
  var list = [];
  var config = scheme.__config__;
  if (scheme['list-type'] === 'picture-card') list.push('<i class="el-icon-plus"></i>');else list.push("<el-button size=\"small\" type=\"primary\" icon=\"el-icon-upload\">".concat(config.buttonText, "</el-button>"));
  if (config.showTip) list.push("<div slot=\"tip\" class=\"el-upload__tip\">\u53EA\u80FD\u4E0A\u4F20\u4E0D\u8D85\u8FC7 ".concat(config.fileSize).concat(config.sizeUnit, " \u7684").concat(scheme.accept, "\u6587\u4EF6</div>"));
  return list.join('\n');
}

// el-upload 子级
// function buildSelfUploadChild(scheme) {
//   const list = []
//   const config = scheme.__config__
//   if (scheme['list-type'] === 'picture-card') list.push('<i class="el-icon-plus"></i>')
//   else list.push(`<el-button size="small" type="primary" icon="el-icon-upload">${config.buttonText}</el-button>`)
//   if (config.showTip) list.push(`<div slot="tip" class="el-upload__tip">只能上传不超过 ${config.fileSize}${config.sizeUnit} 的${scheme.accept}文件</div>`)
//   return list.join('\n')
// }

/**
 * 组装html代码。【入口函数】
 * @param {Object} formConfig 整个表单配置
 * @param {String} type 生成类型，文件或弹窗等
 */
function makeUpHtml(formConfig, type) {
  var htmlList = [];
  confGlobal = formConfig;
  // 判断布局是否都沾满了24个栅格，以备后续简化代码结构
  someSpanIsNot24 = formConfig.fields.some(function (item) {
    return item.__config__.span !== 24;
  });
  // 遍历渲染每个组件成html
  formConfig.fields.forEach(function (el) {
    htmlList.push(layouts[el.__config__.layout](el));
  });
  var htmlStr = htmlList.join('\n');
  // 将组件代码放进form标签
  var temp = buildFormTemplate(formConfig, htmlStr, type);
  // dialog标签包裹代码
  if (type === 'dialog') {
    temp = dialogWrapper(temp);
  }
  confGlobal = null;
  return temp;
}