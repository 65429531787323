"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "configCategotyEdit"
  props: {
    prent: {
      type: Object,
      default: 0
    },
    isCreate: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object
    },
    allTreeList: {
      type: Array
    }
  },
  data: function data() {
    return {
      constants: this.$constants,
      editPram: {
        extra: null,
        // 关联表单id
        name: null,
        pid: null,
        sort: 0,
        status: true,
        type: this.$constants.categoryType[5].value,
        url: null,
        id: 0
      },
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'child',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false
      },
      parentOptions: []
    };
  },
  mounted: function mounted() {
    this.initEditData();
  },
  methods: {
    close: function close() {
      this.$emit('hideEditDialog');
    },
    initEditData: function initEditData() {
      this.parentOptions = selfUtil.addTreeListLabelForCasCard(this.allTreeList);
      if (this.isCreate !== 1) {
        var id = this.prent.id;
        this.editPram.pid = id;
      } else {
        var _this$editData = this.editData,
          extra = _this$editData.extra,
          name = _this$editData.name,
          pid = _this$editData.pid,
          sort = _this$editData.sort,
          status = _this$editData.status,
          type = _this$editData.type,
          url = _this$editData.url,
          _id = _this$editData.id;
        // this.editPram.extra = extra
        this.editPram.name = name;
        this.editPram.pid = pid;
        this.editPram.sort = sort;
        this.editPram.status = status;
        this.editPram.type = type;
        this.editPram.url = url;
        this.editPram.id = _id;
        this.editPram.extra = extra;
      }
    },
    handlerSubmit: (0, _validate.Debounce)(function (formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (!valid) return;
        _this.handlerSaveOrUpdate(_this.isCreate === 0);
      });
    }),
    handlerSaveOrUpdate: function handlerSaveOrUpdate(isSave) {
      var _this2 = this;
      if (isSave) {
        this.editPram.pid = this.prent.id;
        categoryApi.addCategroy(this.editPram).then(function (data) {
          _this2.$emit('hideEditDialog');
          _this2.$message.success('创建分类成功');
        });
      } else {
        this.editPram.pid = Array.isArray(this.editPram.pid) ? this.editPram.pid[0] : this.editPram.pid;
        categoryApi.updateCategroy(this.editPram).then(function (data) {
          _this2.$emit('hideEditDialog');
          _this2.$message.success('更新分类成功');
        });
      }
    }
  }
};