import { render, staticRenderFns } from "./orderDelivery.vue?vue&type=template&id=f8cbd45e&scoped=true"
import script from "./orderDelivery.vue?vue&type=script&lang=js"
export * from "./orderDelivery.vue?vue&type=script&lang=js"
import style0 from "./orderDelivery.vue?vue&type=style&index=0&id=f8cbd45e&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8cbd45e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\25104\\Desktop\\russiavue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f8cbd45e')) {
      api.createRecord('f8cbd45e', component.options)
    } else {
      api.reload('f8cbd45e', component.options)
    }
    module.hot.accept("./orderDelivery.vue?vue&type=template&id=f8cbd45e&scoped=true", function () {
      api.rerender('f8cbd45e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mobile/orderStatistics/orderDelivery.vue"
export default component.exports