"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.beautifierConf = void 0;
exports.camelCase = camelCase;
exports.exportDefault = void 0;
exports.indent = indent;
exports.isNumberStr = isNumberStr;
exports.jsonClone = jsonClone;
exports.titleCase = titleCase;
/**
 * num 小于0，左缩进num*2个空格； 大于0，右缩进num*2个空格。
 * @param {string} str 代码
 * @param {number} num 缩进次数
 * @param {number} len 【可选】缩进单位，空格数
 */
function indent(str, num) {
  var len = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  if (num === 0) return str;
  var isLeft = num < 0;
  var result = [];
  var reg;
  var spaces = '';
  if (isLeft) {
    num *= -1;
    reg = new RegExp("(^\\s{0,".concat(num * len, "})"), 'g');
  } else {
    for (var i = 0; i < num * len; i++) spaces += ' ';
  }
  str.split('\n').forEach(function (line) {
    line = isLeft ? line.replace(reg, '') : spaces + line;
    result.push(line);
  });
  return result.join('\n');
}

// 首字母大小
function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, function (L) {
    return L.toUpperCase();
  });
}

// 下划转驼峰
function camelCase(str) {
  return str.replace(/-[a-z]/g, function (str1) {
    return str1.substr(-1).toUpperCase();
  });
}
function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}
var exportDefault = exports.exportDefault = 'export default ';
var beautifierConf = exports.beautifierConf = {
  html: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'separate',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'normal',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
};
function stringify(obj) {
  return JSON.stringify(obj, function (key, val) {
    if (typeof val === 'function') {
      return "".concat(val);
    }
    return val;
  });
}
function parse(str) {
  JSON.parse(str, function (k, v) {
    if (v.indexOf && v.indexOf('function') > -1) {
      return eval("(".concat(v, ")"));
    }
    return v;
  });
}
function jsonClone(obj) {
  return parse(stringify(obj));
}