"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
var _i18n = _interopRequireDefault(require("@/i18n/i18n"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    levelInfo: {
      type: Object,
      default: {}
    },
    levelList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      grade: '',
      levelStatus: false,
      ruleForm: {
        isSub: false,
        levelId: "",
        uid: this.levelInfo.uid
      }
    };
  },
  created: function created() {
    this.ruleForm.levelId = this.levelInfo.level ? Number(this.levelInfo.level) : '';
  },
  watch: {
    levelInfo: function levelInfo(val) {
      this.ruleForm.uid = val.uid || 0;
      this.ruleForm.levelId = this.levelInfo.level ? Number(this.levelInfo.level) : val.levelId;
    }
  },
  methods: {
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _user.userLevelUpdateApi)(_this.ruleForm).then(function (res) {
            _this.$message.success(_i18n.default.t('bjcg'));
            _this.$parent.$parent.getList();
            _this.$parent.$parent.levelVisible = false;
            _this.$refs[formName].resetFields();
            _this.grade = '';
          });
        } else {
          return false;
        }
      });
    }),
    currentSel: function currentSel() {
      var _this2 = this;
      this.levelList.forEach(function (item) {
        if (item.id == _this2.ruleForm.levelId) {
          _this2.grade = item.grade;
        }
      });
    },
    resetForm: function resetForm(formName) {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.$refs[formName].resetFields();
        _this3.grade = '';
      });
      this.$parent.$parent.levelVisible = false;
    }
  }
};