var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { "padding-bottom": "0" } },
    [
      _c(
        "el-row",
        { staticClass: "baseInfo", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
            [
              _c(
                "el-card",
                { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                [
                  _c(
                    "div",
                    { staticClass: "acea-row row-between-wrapper" },
                    [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v(" " + _vm._s(_vm.$t("ad"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-tag", { attrs: { type: "primary" } }, [
                        _vm._v(_vm._s(_vm.$t("ae"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.viewData
                    ? _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v(_vm._s(_vm.viewData.sales))]
                          ),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "acea-row row-between-wrapper" },
                            [
                              _c("span", { staticClass: "content-time" }, [
                                _vm._v(_vm._s(_vm.$t("af"))),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "content-time" }, [
                                _vm._v(
                                  _vm._s(_vm.viewData.yesterdaySales) +
                                    " " +
                                    _vm._s(_vm.$t("ag"))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
            [
              _c(
                "el-card",
                { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                [
                  _c(
                    "div",
                    { staticClass: "acea-row row-between-wrapper" },
                    [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v(_vm._s(_vm.$t("fwl"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-tag", { attrs: { type: "primary" } }, [
                        _vm._v(_vm._s(_vm.$t("ae"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.viewData
                    ? _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v(_vm._s(_vm.viewData.pageviews))]
                          ),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "acea-row row-between-wrapper" },
                            [
                              _c("span", { staticClass: "content-time" }, [
                                _vm._v(_vm._s(_vm.$t("af"))),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "content-time" }, [
                                _vm._v(_vm._s(_vm.viewData.yesterdayPageviews)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
            [
              _c(
                "el-card",
                { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                [
                  _c(
                    "div",
                    { staticClass: "acea-row row-between-wrapper" },
                    [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v(_vm._s(_vm.$t("ddl"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-tag", { attrs: { type: "primary" } }, [
                        _vm._v(_vm._s(_vm.$t("ae"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.viewData
                    ? _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v(_vm._s(_vm.viewData.orderNum))]
                          ),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "acea-row row-between-wrapper" },
                            [
                              _c("span", { staticClass: "content-time" }, [
                                _vm._v(_vm._s(_vm.$t("af"))),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "content-time" }, [
                                _vm._v(
                                  _vm._s(_vm.viewData.yesterdayOrderNum) +
                                    _vm._s(_vm.$t("dd"))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            _vm._b({ staticClass: "ivu-mb" }, "el-col", _vm.grid, false),
            [
              _c(
                "el-card",
                { attrs: { bordered: false, "dis-hover": "", padding: 12 } },
                [
                  _c(
                    "div",
                    { staticClass: "acea-row row-between-wrapper" },
                    [
                      _c("div", { staticClass: "acea-row align-center" }, [
                        _c("span", { staticClass: "main_tit" }, [
                          _vm._v(_vm._s(_vm.$t("xzyh"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-tag", { attrs: { type: "primary" } }, [
                        _vm._v(_vm._s(_vm.$t("ae"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.viewData
                    ? _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "span",
                            { staticClass: "content-number spBlock my15" },
                            [_vm._v(_vm._s(_vm.viewData.newUserNum))]
                          ),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "acea-row row-between-wrapper" },
                            [
                              _c("span", { staticClass: "content-time" }, [
                                _vm._v(_vm._s(_vm.$t("af"))),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "content-time" }, [
                                _vm._v(
                                  _vm._s(_vm.viewData.yesterdayNewUserNum) +
                                    " " +
                                    _vm._s(_vm.$t("r"))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }