var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("yhbh") } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            attrs: { disabled: "" },
            model: {
              value: _vm.ruleForm.id,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "id", $$v)
              },
              expression: "ruleForm.id",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("yhdz") } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            model: {
              value: _vm.ruleForm.addres,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "addres", $$v)
              },
              expression: "ruleForm.addres",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("yhbz") } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            attrs: { type: "textarea" },
            model: {
              value: _vm.ruleForm.mark,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "mark", $$v)
              },
              expression: "ruleForm.mark",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("hydj") } },
        [
          _c(
            "el-select",
            {
              staticClass: "selWidth",
              attrs: {
                placeholder: _vm.$t("qxz"),
                clearable: "",
                filterable: "",
              },
              model: {
                value: _vm.ruleForm.groupId,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "groupId", $$v)
                },
                expression: "ruleForm.groupId",
              },
            },
            [_vm._v('">')]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("zt") } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleForm.status,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "status", $$v)
                },
                expression: "ruleForm.status",
              },
            },
            [
              _c("el-radio", { attrs: { label: true } }, [
                _vm._v(_vm._s(_vm.$t("kq"))),
              ]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: false } }, [
                _vm._v(_vm._s(_vm.$t("gb"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["admin:user:update"],
                  expression: "['admin:user:update']",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("提交")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("qx")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }