"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PriceChange = _interopRequireDefault(require("../components/PriceChange"));
var _clipboard = _interopRequireDefault(require("clipboard"));
var _order = require("@/api/order");
var _validate = require("@/utils/validate");
var _dialog = require("@/libs/dialog");
var _utils = require("@/utils");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AdminOrder",
  components: {
    PriceChange: _PriceChange.default
  },
  props: {},
  data: function data() {
    return {
      isWriteOff: (0, _utils.isWriteOff)(),
      order: false,
      change: false,
      orderId: '',
      orderInfo: {},
      status: 0,
      title: "",
      payType: "",
      types: ""
    };
  },
  watch: {
    "$route.params.id": function $routeParamsId(newVal) {
      var that = this;
      if (newVal != undefined) {
        that.orderId = newVal;
        that.getIndex();
      }
    }
  },
  mounted: function mounted() {
    // this.orderId = this.$route.params.id;
    this.getIndex();
    this.$nextTick(function () {
      var _this = this;
      var copybtn = document.getElementsByClassName("copy-data");
      var clipboard = new _clipboard.default(copybtn);
      clipboard.on("success", function () {
        _this.$dialog.success("success");
      });
    });
  },
  methods: {
    more: function more() {
      this.order = !this.order;
    },
    modify: function modify(status) {
      this.change = true;
      this.status = status;
    },
    changeclose: function changeclose(msg) {
      this.change = msg;
      this.getIndex();
    },
    getIndex: function getIndex() {
      var _this2 = this;
      var that = this;
      (0, _order.orderDetailApi)({
        orderNo: this.$route.params.id
      }).then(function (res) {
        that.orderInfo = res;
        that.types = res.statusStr.key;
        that.title = res.statusStr.value;
        that.payType = res.payTypeStr;
        _this2.$nextTick(function () {
          var _this3 = this;
          var copybtn = document.getElementsByClassName("copy-data");
          var clipboard = new _clipboard.default(copybtn);
          clipboard.on("success", function () {
            _this3.$dialog.success("success");
          });
        });
      }, function (err) {
        that.$dialog.error(err.msg);
      });
    },
    // async savePrice(opt) {
    //   let that = this,
    //     data = {},
    //     price = opt.price,
    //     remark = opt.remark,
    //     refundStatus = that.orderInfo.refundStatus,
    //     refundPrice = opt.refundPrice;
    //   if (that.status == 0 && refundStatus === 0) {
    //     try {
    //       await this.$validator({
    //         price: [
    //           required(required.message("金额")),
    //           num(num.message("金额"))
    //         ]
    //       }).validate({ price });
    //     } catch (e) {
    //       return validatorDefaultCatch(e);
    //     }
    //     data.price = price;
    //     data.orderId  = opt.orderId;
    //     setAdminOrderPrice(data).then(
    //       function() {
    //         that.change = false;
    //         that.$dialog.success("改价成功");
    //         that.getIndex();
    //       },
    //       function() {
    //         that.change = false;
    //         that.$dialog.error("改价失败");
    //       }
    //     );
    //   } else if (that.status == 0 && that.orderInfo.refund_status === 1) {
    //     try {
    //       await this.$validator({
    //         refund_price: [
    //           required(required.message("金额")),
    //           num(num.message("金额"))
    //         ]
    //       }).validate({ refund_price });
    //     } catch (e) {
    //       return validatorDefaultCatch(e);
    //     }
    //     data.price = refund_price;
    //     data.type = opt.type;
    //     setOrderRefund(data).then(
    //       res => {
    //         that.change = false;
    //         that.$dialog.success(res.msg);
    //         that.getIndex();
    //       },
    //       err => {
    //         that.change = false;
    //         that.$dialog.error(err.msg);
    //         that.getIndex();
    //       }
    //     );
    //   } else {
    //     try {
    //       await this.$validator({
    //         remark: [required(required.message("备注"))]
    //       }).validate({ remark });
    //     } catch (e) {
    //       return validatorDefaultCatch(e);
    //     }
    //     data.remark = remark;
    //     setAdminOrderRemark(data).then(
    //       res => {
    //         that.change = false;
    //         that.$dialog.success(res.msg);
    //         that.getIndex();
    //       },
    //       err => {
    //         that.change = false;
    //         that.$dialog.error(err.msg);
    //       }
    //     );
    //   }
    // },
    offlinePay: function offlinePay() {
      var _this4 = this;
      setOfflinePay({
        orderId: this.orderInfo.orderId
      }).then(function (res) {
        _this4.$dialog.success(res.msg);
        _this4.getIndex();
      }, function (err) {
        _this4.$dialog.error(err.msg);
      });
    }
  }
};