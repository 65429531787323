var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.orderDatalist
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("djmc"),
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "description",
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("yhxx"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(_vm.$t("yhnc")) +
                          "：" +
                          _vm._s(_vm.orderDatalist.nikeName)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(_vm.$t("bddh")) +
                          "：" +
                          _vm._s(
                            _vm.orderDatalist.phone
                              ? _vm.orderDatalist.phone
                              : "无"
                          )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.orderDatalist.statusStr.key === "toBeWrittenOff"
                          ? "提货信息"
                          : "收货信息"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(
                          _vm.orderDatalist.statusStr.key === "toBeWrittenOff"
                            ? "提货人"
                            : "收货人"
                        ) +
                          "：" +
                          _vm._s(_vm.orderDatalist.realName)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(
                          _vm.orderDatalist.statusStr.key === "toBeWrittenOff"
                            ? "提货电话"
                            : "收货电话"
                        ) +
                          "：" +
                          _vm._s(_vm.orderDatalist.userPhone)
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.orderDatalist.statusStr.key !== "toBeWrittenOff"
                      ? _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            _vm._s(
                              _vm.orderDatalist.statusStr.key ===
                                "toBeWrittenOff"
                                ? "提货地址"
                                : "收货地址"
                            ) +
                              "：" +
                              _vm._s(_vm.orderDatalist.userAddress)
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("ddxx"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(_vm.$t("ddbh")) +
                          "：" +
                          _vm._s(_vm.orderDatalist.orderId)
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "description-term",
                        staticStyle: { color: "red" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("ddzt")) +
                            "：" +
                            _vm._s(_vm.orderDatalist.statusStr.value)
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(_vm.$t("spzs")) +
                          "：" +
                          _vm._s(_vm.orderDatalist.totalNum)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(_vm.$t("spzj")) +
                          "：" +
                          _vm._s(_vm.orderDatalist.proTotalPrice)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(_vm.$t("sjzf")) +
                          "：" +
                          _vm._s(_vm.orderDatalist.payPrice)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(_vm.$t("cjsj")) +
                          "：" +
                          _vm._s(_vm.orderDatalist.createTime)
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.orderDatalist.refundReasonTime
                      ? _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            _vm._s(_vm.$t("tksj")) +
                              "：" +
                              _vm._s(_vm.orderDatalist.refundReasonTime)
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(_vm.$t("zffs")) +
                          "：" +
                          _vm._s(_vm.orderDatalist.payTypeStr)
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.orderDatalist.shippingType === 2 &&
                    _vm.orderDatalist.statusStr.key === "notShipped"
                      ? _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            _vm._s(_vm.$t("mdmc")) +
                              "：" +
                              _vm._s(_vm.orderDatalist.storeName)
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.orderDatalist.shippingType === 2 &&
                    _vm.orderDatalist.statusStr.key === "notShipped"
                      ? _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            _vm._s(_vm.$t("hxm")) +
                              _vm._s(_vm.orderDatalist.user_phone)
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        _vm._s(_vm.$t("sjbz")) +
                          _vm._s(_vm.orderDatalist.remark)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.orderDatalist.deliveryType === "send"
                    ? [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("psxx"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              _vm._s(_vm.$t("shrxm")) +
                                _vm._s(_vm.orderDatalist.deliveryName)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              _vm._s(_vm.$t("shrdh")) +
                                _vm._s(_vm.orderDatalist.deliveryId)
                            ),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDatalist.mark
                    ? [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("yhbz"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(_vm._s(_vm.orderDatalist.mark)),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }