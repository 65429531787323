//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { productLstApi, productDeleteApi, categoryApi, putOnShellApi, offShellApi, productHeadersApi, productExportApi, restoreApi, productExcelApi } from '@/api/store';
import { getToken } from '@/utils/auth';
import taoBao from './taoBao';
import { checkPermi } from "@/utils/permission"; // 权限判断函数
import i18n from '@/i18n/i18n';
export default {
  name: 'ProductList',
  components: {
    taoBao: taoBao
  },
  data: function data() {
    return {
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      // roterPre: roterPre,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        cateId: '',
        keywords: '',
        type: '1'
      },
      categoryList: [],
      merCateList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false
    };
  },
  mounted: function mounted() {
    this.goodHeade();
    this.getList();
    this.getCategorySelect();
    this.checkedCities = this.$cache.local.has('goods_stroge') ? this.$cache.local.getJSON('goods_stroge') : this.checkedCities;
  },
  methods: {
    checkPermi: checkPermi,
    handleRestore: function handleRestore(id) {
      var _this = this;
      this.$modalSure(i18n.t('hfsp')).then(function () {
        restoreApi(id).then(function (res) {
          _this.$message.success(i18n.t('czcg'));
          _this.goodHeade();
          _this.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleCloseMod: function handleCloseMod(item) {
      this.dialogVisible = item;
      this.goodHeade();
      this.getList();
    },
    // 复制
    onCopy: function onCopy() {
      this.dialogVisible = true;
    },
    // 导出
    exports: function exports() {
      productExcelApi({
        cateId: this.tableFrom.cateId,
        keywords: this.tableFrom.keywords,
        type: this.tableFrom.type
      }).then(function (res) {
        window.location.href = res.fileName;
      });
    },
    // 获取商品表单头数量
    goodHeade: function goodHeade() {
      var _this2 = this;
      productHeadersApi().then(function (res) {
        _this2.headeNum = res;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this3 = this;
      categoryApi({
        status: -1,
        type: 1
      }).then(function (res) {
        _this3.merCateList = res;
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      productLstApi(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, type) {
      var _this5 = this;
      this.$modalSure("delete id  ".concat(id, " \u7684\u5546\u54C1")).then(function () {
        var deleteFlag = type == 5 ? 'delete' : 'recycle';
        productDeleteApi(id, deleteFlag).then(function () {
          _this5.$message.success(i18n.t('sccg'));
          _this5.getList();
          _this5.goodHeade();
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this6 = this;
      row.isShow ? putOnShellApi(row.id).then(function () {
        _this6.$message.success(i18n.t('sjcg'));
        _this6.getList();
        _this6.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      }) : offShellApi(row.id).then(function () {
        _this6.$message.success(i18n.t('xjcg'));
        _this6.getList();
        _this6.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    }
  }
};