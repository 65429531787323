//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddOrUpdate from './ebcardinfo-add-and-update';
import * as api from '@/api/ebcardinfo';
export default {
  data: function data() {
    return {
      dataForm: {
        key: ''
      },
      dataList: [],
      tableFrom: {
        page: 1,
        limit: 20
      },
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    };
  },
  components: {
    AddOrUpdate: AddOrUpdate
  },
  mounted: function mounted() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList: function getDataList() {
      var _this = this;
      this.dataListLoading = true;
      api.ebcardinfoListApi(this.tableFrom).then(function (res) {
        _this.dataList = res.list;
        _this.totalPage = res.total;
        _this.dataListLoading = false;

        // TODO 获取数据列表
      });
    },
    // 每页数
    sizeChangeHandle: function sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle: function currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle: function selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle: function addOrUpdateHandle(id) {
      var _this2 = this;
      this.addOrUpdateVisible = true;
      this.$nextTick(function () {
        _this2.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle: function deleteHandle(id) {
      var _this3 = this;
      var ids = id ? [id] : this.dataListSelections.map(function (item) {
        return item.id;
      });
      this.$confirm("\u60A8\u786E\u5B9A\u5BF9[id=".concat(ids.join(','), "]\u8FDB\u884C[").concat(id ? '删除' : '批量删除', "]\u64CD\u4F5C?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        api.ebcardinfoDeleteApi(id).then(function (res) {
          _this3.getDataList();
          // TODO 处理删除
        });
      });
    }
  }
};