var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: _vm.$t("spxx") } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: _vm.$t("spxq") } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: _vm.$t("qtsz") } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("spmc"), prop: "storeName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "249",
                              placeholder: _vm.$t("qsrspmc"),
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.storeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "storeName", $$v)
                              },
                              expression: "formValidate.storeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("spfl"), prop: "cateIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: _vm.$t("qxz") },
                              model: {
                                value: _vm.formValidate.cateIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "cateIds", $$v)
                                },
                                expression: "formValidate.cateIds",
                              },
                            },
                            _vm._l(_vm.merCateList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("danwei"), prop: "unitName" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("qsrdw"),
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.unitName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "unitName", $$v)
                              },
                              expression: "formValidate.unitName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("spjj"), prop: "storeInfo" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "250",
                              rows: 3,
                              placeholder: _vm.$t("qsrspjj"),
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.storeInfo,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "storeInfo", $$v)
                              },
                              expression: "formValidate.storeInfo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("spfmt"), prop: "image" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formValidate.image },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("splbt"),
                            prop: "sliderImages",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.sliderImages,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "true" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", { attrs: { src: item } }),
                                      _vm._v(" "),
                                      !_vm.isDisabled
                                        ? _c("i", {
                                            staticClass: "el-icon-error btndel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemove(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.sliderImages.length < 10 &&
                              !_vm.isDisabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap("2")
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("spgg"), props: "specType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeSpec(
                                    _vm.formValidate.specType
                                  )
                                },
                              },
                              model: {
                                value: _vm.formValidate.specType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "specType", $$v)
                                },
                                expression: "formValidate.specType",
                              },
                            },
                            [
                              _vm._v("\n                //"),
                              _c(
                                "el-radio",
                                {
                                  staticClass: "radio",
                                  attrs: { label: false },
                                },
                                [_vm._v(_vm._s(_vm.$t("dgg")))]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v(_vm._s(_vm.$t("duogg"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.specType && !_vm.isDisabled
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("xzgg"), prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.formValidate.selectRule,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "selectRule",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.selectRule",
                                      },
                                    },
                                    _vm._l(_vm.ruleList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.ruleName,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr20",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.confirm },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("qr")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      on: { click: _vm.addRule },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("tjgg")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            _vm._l(
                              _vm.formValidate.attr,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row row-middle" },
                                    [
                                      _c("span", { staticClass: "mr5" }, [
                                        _vm._v(_vm._s(item.attrName)),
                                      ]),
                                      _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemoveAttr(index)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "rulesBox" },
                                    [
                                      _vm._l(
                                        item.attrValue,
                                        function (j, indexn) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: indexn,
                                              staticClass: "mb5 mr10",
                                              attrs: {
                                                closable: "",
                                                size: "medium",
                                                "disable-transitions": false,
                                              },
                                              on: {
                                                close: function ($event) {
                                                  return _vm.handleClose(
                                                    item.attrValue,
                                                    indexn
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(j) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      item.inputVisible
                                        ? _c("el-input", {
                                            ref: "saveTagInput",
                                            refInFor: true,
                                            staticClass: "input-new-tag",
                                            attrs: { size: "small" },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.createAttr(
                                                  item.attrValue.attrsVal,
                                                  index
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.createAttr(
                                                  item.attrValue.attrsVal,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.attrValue.attrsVal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item.attrValue,
                                                  "attrsVal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.attrValue.attrsVal",
                                            },
                                          })
                                        : _c(
                                            "el-button",
                                            {
                                              staticClass: "button-new-tag",
                                              attrs: { size: "small" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showInput(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "+ " + _vm._s(_vm.$t("tianjia"))
                                              ),
                                            ]
                                          ),
                                    ],
                                    2
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _vm.isBtn
                            ? _c(
                                "el-col",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 6,
                                        lg: 9,
                                        md: 9,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: _vm.$t("guige") } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: _vm.$t("qsrgg"),
                                            },
                                            model: {
                                              value: _vm.formDynamic.attrsName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDynamic,
                                                  "attrsName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDynamic.attrsName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 6,
                                        lg: 9,
                                        md: 9,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: _vm.$t("ggz") } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: _vm.$t("qsrggz"),
                                            },
                                            model: {
                                              value: _vm.formDynamic.attrsVal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDynamic,
                                                  "attrsVal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDynamic.attrsVal",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 12,
                                        lg: 6,
                                        md: 6,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { staticClass: "noLeft" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "mr15",
                                              attrs: { type: "primary" },
                                              on: { click: _vm.createAttrName },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("qd")))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            { on: { click: _vm.offAttrName } },
                                            [_vm._v(_vm._s(_vm.$t("qx")))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isBtn
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: {
                                        type: "primary",
                                        icon: "md-add",
                                      },
                                      on: { click: _vm.addBtn },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("tjxgg")))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.attr.length > 0 &&
                  _vm.formValidate.specType &&
                  !_vm.isDisabled
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("plsz") } },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.oneFormBatch,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t("tp"),
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1369417214
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    maxlength: "9",
                                                    min: "0.01",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        align: "center",
                                        label: _vm.$t("cz"),
                                        "min-width": "80",
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "submission",
                                            attrs: { type: "text" },
                                            on: { click: _vm.batchAdd },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("pltj")))]
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                    [
                      _vm.formValidate.specType === false
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.OneattrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t("tp"),
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "dan",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.formValidate.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1357914119
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    disabled: _vm.isDisabled,
                                                    maxlength: "9",
                                                    min: "0.01",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$route.params.id && _vm.showAll
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("qbsku") } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "default",
                                    disabled: _vm.isDisabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAllSku()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("zanshi")))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.attr.length > 0 &&
                      _vm.formValidate.specType
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              class: _vm.isDisabled
                                ? "disLabel"
                                : "disLabelmoren",
                              attrs: { label: _vm.$t("spsx") },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm.manyTabDate
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              align: "center",
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t("tp"),
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "duo",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3478746955
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    disabled: _vm.isDisabled,
                                                    maxlength: "9",
                                                    min: "0.01",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        3
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  !_vm.isDisabled
                                    ? _c("el-table-column", {
                                        key: "3",
                                        attrs: {
                                          align: "center",
                                          label: _vm.$t("caozuo"),
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "submission",
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delAttrTable(
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("sc"))
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1440532261
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1 && !_vm.isDisabled,
                      expression: "currentTab === 1 && !isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("spxq") } },
                        [
                          _c("Tinymce", {
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "content", $$v)
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1 && _vm.isDisabled,
                      expression: "currentTab === 1 && isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: _vm.$t("spxq") } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.formValidate.content || "无"),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2,
                      expression: "currentTab === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("paixu") } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  placeholder: _vm.$t("qsrpx"),
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.formValidate.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "sort", $$v)
                                  },
                                  expression: "formValidate.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("xnxl") } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  placeholder: _vm.$t("qsrsnxl"),
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.formValidate.ficti,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "ficti", $$v)
                                  },
                                  expression: "formValidate.ficti",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab > 0,
                          expression: "currentTab>0",
                        },
                      ],
                      staticClass: "submission priamry_border",
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v(_vm._s(_vm.$t("syb")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab < 2,
                          expression: "currentTab<2",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest("formValidate")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("xyb")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.currentTab === 2 || _vm.$route.params.id) &&
                            !_vm.isDisabled,
                          expression:
                            "(currentTab===2 || $route.params.id) && !isDisabled",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("tijiao")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("CreatTemplates", {
        ref: "addTemplates",
        on: { getList: _vm.getShippingList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }