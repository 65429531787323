var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _vm._v(" "),
                _c(
                  "el-dropdown",
                  {
                    staticClass: "right-menu-item",
                    attrs: { trigger: "click" },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "language",
                            "class-name": "language-icon",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div"),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: { disabled: _vm.$i18n.locale == "zh" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.toggleLang("zh")
                              },
                            },
                          },
                          [_vm._v("中文")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: { disabled: _vm.$i18n.locale == "en" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.toggleLang("en")
                              },
                            },
                          },
                          [_vm._v("Русский язык")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm._v("\n        " + _vm._s(_vm.JavaInfo.realName)),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v(_vm._s(_vm.$t("a")))])],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isPhone
                    ? _c(
                        "router-link",
                        { attrs: { to: { path: "/maintain/user" } } },
                        [
                          _c("el-dropdown-item", [
                            _vm._v(_vm._s(_vm.$t("ab"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("ac")))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }