"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _validate = require("@/utils/validate");
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var defaultObj = {
  avatar: '',
  comment: '',
  nickname: '',
  pics: '',
  productId: '',
  productScore: null,
  serviceScore: null,
  sku: ''
};
var _default = exports.default = {
  name: "creatComment",
  props: {
    num: {
      type: Number,
      required: 0
    }
  },
  data: function data() {
    var checkProductScore = function checkProductScore(rule, value, callback) {
      if (!value) {
        return callback(new Error('商品分数不能为空'));
      } else {
        callback();
      }
    };
    var checkServiceScore = function checkServiceScore(rule, value, callback) {
      if (!value) {
        return callback(new Error('服务分数不能为空'));
      } else {
        callback();
      }
    };
    return {
      loadingbtn: false,
      loading: false,
      pics: [],
      image: '',
      formValidate: Object.assign({}, defaultObj),
      rules: {
        avatar: [{
          required: true,
          message: '请选择用户头像',
          trigger: 'change'
        }],
        productId: [{
          required: true,
          message: '请选择商品',
          trigger: 'change'
        }],
        comment: [{
          required: true,
          message: '请填写评价内容',
          trigger: 'blur'
        }],
        nickname: [{
          required: true,
          message: '请填写用户名称',
          trigger: 'blur'
        }],
        pics: [{
          required: true,
          message: '请选择评价图片',
          trigger: 'change'
        }],
        productScore: [{
          required: true,
          validator: checkProductScore,
          trigger: 'blur'
        }],
        serviceScore: [{
          required: true,
          validator: checkServiceScore,
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    num: {
      handler: function handler(val) {
        this.resetForm('formValidate');
      },
      deep: true
    }
  },
  methods: {
    changeGood: function changeGood() {
      var _this = this;
      this.$modalGoodList(function (row) {
        _this.image = row.image;
        _this.formValidate.productId = row.id;
        _this.formValidate.sku = row.attrValue[0].suk;
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      _this.$modalUpload(function (img) {
        tit === '1' ? _this.formValidate.avatar = img[0].sattDir : img.map(function (item) {
          _this.pics.push(item.sattDir);
        });
      }, tit, 'store');
    },
    handleRemove: function handleRemove(i) {
      this.pics.splice(i, 1);
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this2 = this;
      this.formValidate.pics = this.pics.length > 0 ? JSON.stringify(this.pics) : '';
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.loadingbtn = true;
          (0, _store.replyCreatApi)(_this2.formValidate).then(function () {
            _this2.$message.success("新增成功");
            setTimeout(function () {
              // this.clear();
              _this2.$emit('getList');
            }, 600);
            _this2.loadingbtn = false;
          }).catch(function () {
            _this2.loadingbtn = false;
          });
        } else {
          return false;
        }
      });
    }),
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
      this.pics = [];
      this.formValidate.pics = '';
    },
    info: function info() {
      var _this3 = this;
      this.loading = true;
      (0, _store.replyInfoApi)(this.formValidate).then(function () {
        _this3.formValidate = res;
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
    },
    // 移动
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = 'move';
    },
    handleDragEnter: function handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = 'move';
      if (item === this.dragging) {
        return;
      }
      var newItems = _toConsumableArray(this.pics);
      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      this.pics = newItems;
    }
  }
};