"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = _interopRequireDefault(require("element-ui"));
require("@/styles/element-variables.scss");
var _index = _interopRequireDefault(require("./index.vue"));
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size
});
var articleFrom = {};
articleFrom.install = function (Vue, options) {
  var ToastConstructor = Vue.extend(_index.default);
  // 生成一个该子类的实例
  var instance = new ToastConstructor();
  instance.$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);
  Vue.prototype.$modalArticle = function (callback, handle) {
    instance.visible = true;
    instance.callback = callback;
    instance.handle = handle;
  };
};
var _default = exports.default = articleFrom;