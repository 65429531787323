var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading && !_vm.loaded
    ? _c(
        "div",
        {
          staticClass: "Loads acea-row row-center-wrapper",
          staticStyle: { "margin-top": ".2rem", "font-size": "12px" },
        },
        [
          _vm.loading
            ? [
                _c("div", {
                  staticClass:
                    "iconfont icon-jiazai loading acea-row row-center-wrapper",
                }),
                _vm._v("\n    " + _vm._s(_vm.$t("zzjzz")) + "\n\n  "),
              ]
            : [_vm._v("\n    " + _vm._s(_vm.$t("zzjzz")) + "\n  ")],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }