var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container" }, [
    _c("div", { staticClass: "public-wrapper" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "iconfont icon-xiangxishuju" }),
        _vm._v(_vm._s(_vm.$t("xxsj")) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "nav acea-row row-between-wrapper" }, [
        _c("div", { staticClass: "data" }, [_vm._v(_vm._s(_vm.$t("rq")))]),
        _vm._v(" "),
        _c("div", { staticClass: "browse" }, [_vm._v(_vm._s(_vm.$t("dds")))]),
        _vm._v(" "),
        _c("div", { staticClass: "turnover" }, [_vm._v(_vm._s(_vm.$t("cje")))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "conter" },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "item acea-row row-between-wrapper" },
            [
              _c("div", { staticClass: "data" }, [_vm._v(_vm._s(item.time))]),
              _vm._v(" "),
              _c("div", { staticClass: "browse" }, [
                _vm._v(_vm._s(item.count)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "turnover" }, [
                _vm._v(_vm._s(item.price)),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }