"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _addClerk = _interopRequireDefault(require("./addClerk"));
var _storePoint = require("@/api/storePoint");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'clerkList',
  components: {
    addClerkList: _addClerk.default
  },
  data: function data() {
    return {
      storeSelectList: [],
      artFrom: {
        page: 1,
        limit: 20,
        storeId: ''
      },
      loading: false,
      tableData: [],
      total: 0
    };
  },
  created: function created() {
    this.tableList();
    this.storeList();
  },
  methods: {
    //是否显示
    onchangeIsShow: function onchangeIsShow(id, isShow) {
      var that = this;
      (0, _storePoint.storeStaffUpdateStatusApi)({
        id: id,
        status: isShow
      }).then(function () {
        that.$message.success("操作成功");
        that.tableList();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    },
    storeList: function storeList() {
      var _this = this;
      var artFrom = {
        page: 1,
        limit: 9999,
        status: '1',
        keywords: ''
      };
      (0, _storePoint.storeListApi)(artFrom).then(function (res) {
        _this.storeSelectList = res.list;
      });
    },
    tableList: function tableList() {
      var that = this;
      that.loading = true;
      (0, _storePoint.storeStaffListApi)(that.artFrom).then(function (res) {
        that.loading = false;
        that.tableData = res.list;
        that.total = res.total;
      }).catch(function (res) {
        that.$message.error(res.message);
      });
    },
    //切换页数
    pageChange: function pageChange(index) {
      this.artFrom.page = index;
      this.tableList();
    },
    //切换显示条数
    sizeChange: function sizeChange(index) {
      this.artFrom.limit = index;
      this.tableList();
    },
    //搜索
    search: function search() {
      this.artFrom.page = 1;
      this.tableList();
    },
    //刪除
    storeDelete: function storeDelete(id) {
      var that = this;
      that.$modalSure().then(function () {
        (0, _storePoint.storeStaffDeleteApi)({
          id: id
        }).then(function () {
          that.$message.success('删除成功');
          that.tableList();
        });
      }).catch(function (res) {
        that.$message.error(res.message);
      });
    },
    //添加
    add: function add() {
      this.$refs.template.dialogFormVisible = true;
    },
    //编辑
    edit: function edit(id) {
      this.$refs.template.dialogFormVisible = true;
      this.$refs.template.getInfo(id);
    }
  }
};