"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _financial = require("@/api/financial");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AccountsCapital',
  data: function data() {
    return {
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        title: '',
        dateLimit: '',
        keywords: '',
        page: 1,
        limit: 20
      },
      categoryId: '',
      fromList: this.$constants.fromList,
      options: [{
        value: 'recharge',
        label: '充值支付'
      }, {
        value: 'admin',
        label: '后台操作'
      }, {
        value: 'productRefund',
        label: '商品退款'
      }, {
        value: 'payProduct',
        label: '购买商品'
      }]
    };
  },
  mounted: function mounted() {
    // this.getTypes()
    this.getList();
  },
  methods: {
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _financial.monitorListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    selectType: function selectType(e) {
      this.getList();
    }
  }
};