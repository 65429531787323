"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));
var _orderDetail = _interopRequireDefault(require("./orderDetail"));
var _orderSend = _interopRequireDefault(require("./orderSend"));
var _orderVideoSend = _interopRequireDefault(require("./orderVideoSend"));
var _storePoint = require("@/api/storePoint");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _utils = require("@/utils");
var _store = require("@/api/store");
var _permission = require("@/utils/permission");
var _i18n = _interopRequireDefault(require("@/i18n/i18n"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  payimg: '',
  tmoney: 0,
  name: 'orderlistDetails',
  components: {
    cardsData: _index.default,
    zbParser: _ZBParser.default,
    detailsFrom: _orderDetail.default,
    orderSend: _orderSend.default,
    orderVideoSend: _orderVideoSend.default
  },
  data: function data() {
    return {
      RefuseVisible: false,
      RefuseData: {},
      orderId: '',
      refundVisible: false,
      refundData: {},
      dialogVisibleJI: false,
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 10,
        orderNo: 0
      },
      LogLoading: false,
      isCreate: 1,
      editData: null,
      dialogVisible: false,
      dialogVisibles: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        status: 'all',
        dateLimit: '',
        orderNo: '',
        page: 1,
        limit: 10,
        type: 0
      },
      orderChartType: {},
      timeVal: [],
      fromList: this.$constants.fromList,
      fromType: [{
        value: 'all',
        text: _i18n.default.t('qb')
      }, {
        value: 'info',
        text: _i18n.default.t('pt')
      }
      // {value:'pintuan',text:'拼团'},
      // {value:'bragin',text:'砍价'},
      // {value:'miaosha',text:'秒杀'},
      ],
      selectionList: [],
      ids: '',
      orderids: '',
      cardLists: [],
      isWriteOff: (0, _utils.isWriteOff)(),
      proType: 0,
      active: false
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getOrderStatusNum();
    // this.getOrderListData();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    // 核销订单
    onWriteOff: function onWriteOff(row) {
      var _this = this;
      this.$modalSure(_i18n.default.t('hxddm')).then(function () {
        (0, _order.writeUpdateApi)(row.verifyCode).then(function () {
          _this.$message.success(_i18n.default.t('hxcg'));
          _this.tableFrom.page = 1;
          _this.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 拒绝退款
    RefusehandleClose: function RefusehandleClose() {
      this.RefuseVisible = false;
    },
    onOrderRefuse: function onOrderRefuse(row) {
      this.orderids = row.orderId;
      this.RefuseData = {
        orderId: row.orderId,
        reason: ''
      };
      this.RefuseVisible = true;
    },
    RefusehandlerSubmit: function RefusehandlerSubmit(formValue) {
      var _this2 = this;
      (0, _order.orderRefuseApi)({
        orderNo: this.orderids,
        reason: formValue.reason
      }).then(function (data) {
        _this2.$message.success(_i18n.default.t('czcg'));
        _this2.RefuseVisible = false;
        _this2.getList();
      });
    },
    // 立即退款
    refundhandleClose: function refundhandleClose() {
      this.refundVisible = false;
    },
    onOrderRefund: function onOrderRefund(row) {
      this.refundData = {
        orderId: row.orderId,
        amount: row.payPrice,
        type: ''
      };
      this.orderids = row.orderId;
      this.refundVisible = true;
    },
    refundhandlerSubmit: function refundhandlerSubmit(formValue) {
      var _this3 = this;
      (0, _order.orderRefundApi)({
        amount: formValue.amount,
        orderNo: this.orderids
      }).then(function (data) {
        _this3.$message.success(_i18n.default.t('czcg'));
        _this3.refundVisible = false;
        _this3.getList();
      });
    },
    // 发送
    sendOrder: function sendOrder(row) {
      if (row.type === 0) {
        this.$refs.send.modals = true;
        this.$refs.send.getList();
        this.$refs.send.sheetInfo();
        this.getOrderStatusNum();
      } else {
        this.$refs.videoSend.modals = true;
        if (!JSON.parse(sessionStorage.getItem('videoExpress'))) this.$refs.videoSend.companyGetList();
      }
      this.orderId = row.orderId;
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this4 = this;
      if (row.isDel) {
        this.$modalSure().then(function () {
          (0, _order.orderDeleteApi)({
            orderNo: row.orderId
          }).then(function () {
            _this4.$message.success(_i18n.default.t('sccg'));
            _this4.tableData.data.splice(idx, 1);
          });
        });
      } else {
        this.$confirm(_i18n.default.t('nxzdddczyhwscddd'), _i18n.default.t('ts'), {
          confirmButtonText: _i18n.default.t('qd'),
          type: 'error'
        });
      }
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.getDetail(id);
      this.$refs.orderDetail.dialogVisible = true;
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this5 = this;
      this.dialogVisibleJI = true;
      this.LogLoading = true;
      this.tableFromLog.orderNo = id;
      (0, _order.orderLogApi)(this.tableFromLog).then(function (res) {
        _this5.tableDataLog.data = res.list;
        _this5.tableDataLog.total = res.total;
        _this5.LogLoading = false;
      }).catch(function () {
        _this5.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.onOrderLog();
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.onOrderLog();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 备注
    onOrderMark: function onOrderMark(row) {
      var _this6 = this;
      this.$prompt(_i18n.default.t('ddbz'), {
        confirmButtonText: _i18n.default.t('qd'),
        cancelButtonText: _i18n.default.t('qx'),
        inputErrorMessage: _i18n.default.t('qsrddbz'),
        inputType: 'textarea',
        inputValue: row.remark,
        inputPlaceholder: _i18n.default.t('qsrddbz'),
        inputValidator: function inputValidator(value) {
          if (!value) return _i18n.default.t('srbnwk');
        }
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _order.orderMarkApi)({
          mark: value,
          orderNo: row.orderId
        }).then(function () {
          _this6.$message.success(_i18n.default.t('czcg'));
          _this6.getList();
        });
      }).catch(function () {
        _this6.$message.info(_i18n.default.t('qxsr'));
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.orderId);
      });
      this.ids = data.join(',');
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 编辑
    edit: function edit(row) {
      this.orderId = row.orderId;
      this.editData = {
        orderId: row.orderId,
        totalPrice: row.totalPrice,
        totalPostage: row.totalPostage,
        payPrice: row.payPrice,
        payPostage: row.payPostage,
        gainIntegral: row.gainIntegral
      };
      this.dialogVisible = true;
    },
    // 编辑
    moneyOk: function moneyOk(row) {
      this.orderId = row.orderId;
      this.payimg = row.refundReasonWapImg;
      this.tmoney = row.totalPrice;
      this.editData = {
        orderId: row.orderId,
        payPrice: row.totalPrice,
        status: 1
      };
      this.dialogVisible = true;
    },
    payimgok: function payimgok() {
      var _this7 = this;
      console.log(this.editData);
      (0, _order.payOk)(this.editData).then(function () {
        _this7.$emit("closechange", false);
        _this7.getList();
        _this7.getOrderStatusNum();
        _this7.$dialog.success(_i18n.default.t('qrcg'));
      }).catch(function (error) {
        _this7.$dialog.error(error.message);
      });
      this.dialogVisible = false;
    },
    // 编辑
    moneyOks: function moneyOks(row) {
      this.editData = {
        orderId: row.orderId,
        payPrice: null,
        status: 3
      };
      this.dialogVisibles = true;
    },
    takeok: function takeok() {
      var _this8 = this;
      /*this.editData = {
        orderId: res.orderId,
        //payPrice: res.totalPrice,
        status: 3
        }*/
      (0, _order.payOk)(this.editData).then(function () {
        _this8.$emit("closechange", false);
        _this8.getList();
        _this8.getOrderStatusNum();
        _this8.$dialog.success(_i18n.default.t('qrcg'));
      }).catch(function (error) {
        _this8.$dialog.error(error.message);
      });
      this.dialogVisibles = false;
    },
    payimgclose: function payimgclose() {
      this.dialogVisible = false;
    },
    handlerSubmit: function handlerSubmit(formValue) {
      var _this9 = this;
      var data = {
        orderNo: formValue.orderId,
        payPrice: formValue.payPrice
      };
      (0, _order.updatePriceApi)(data).then(function (data) {
        _this9.$message.success(_i18n.default.t('bjsjcg'));
        _this9.dialogVisible = false;
        _this9.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this10 = this;
      this.listLoading = true;
      (0, _order.orderListApi)(this.tableFrom).then(function (res) {
        _this10.tableData.data = res.list || [];
        _this10.tableData.total = res.total;
        _this10.listLoading = false;
        _this10.checkedCities = _this10.$cache.local.has('order_stroge') ? _this10.$cache.local.getJSON('order_stroge') : _this10.checkedCities;
      }).catch(function () {
        _this10.listLoading = false;
      });
    },
    // 数据统计
    getOrderListData: function getOrderListData() {
      var _this11 = this;
      (0, _order.orderListDataApi)({
        dateLimit: this.tableFrom.dateLimit
      }).then(function (res) {
        _this11.cardLists = [{
          name: _i18n.default.t('ddsl'),
          count: res.count,
          color: '#1890FF',
          class: 'one',
          icon: 'icondingdan'
        }, {
          name: _i18n.default.t('ddje'),
          count: res.amount,
          color: '#A277FF',
          class: 'two',
          icon: 'icondingdanjine'
        }
        // { name: '微信支付金额', count: res.weChatAmount, color:'#EF9C20',class:'three',icon:'iconweixinzhifujine' },
        // { name: '余额支付金额', count: res.yueAmount,color:'#1BBE6B',class:'four',icon:'iconyuezhifujine2' }
        ];
      });
    },
    // 获取各状态数量
    getOrderStatusNum: function getOrderStatusNum() {
      var _this12 = this;
      (0, _order.orderStatusNumApi)({
        dateLimit: this.tableFrom.dateLimit,
        type: this.tableFrom.type
      }).then(function (res) {
        _this12.orderChartType = res;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    exports: function exports() {
      var data = {
        dateLimit: this.tableFrom.dateLimit,
        orderNo: this.tableFrom.orderNo,
        status: this.tableFrom.status,
        type: this.tableFrom.type
      };
      (0, _store.orderExcelApi)(data).then(function (res) {
        window.open(res.fileName);
      });
    },
    //打印小票
    onOrderPrint: function onOrderPrint(data) {
      var _this13 = this;
      (0, _order.orderPrint)(data.orderId).then(function (res) {
        _this13.$modal.msgSuccess(_i18n.default.t('dycg'));
      }).catch(function (error) {
        _this13.$modal.msgError(error.message);
      });
    }
  }
};