"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _i18n = _interopRequireDefault(require("@/i18n/i18n"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'StoreAttr',
  data: function data() {
    return {
      formDynamic: {
        ruleName: '',
        ruleValue: []
      },
      tableFrom: {
        page: 1,
        limit: 20,
        keywords: ''
      },
      tableData: {
        data: [],
        loading: false,
        total: 0
      },
      listLoading: true,
      selectionList: [],
      multipleSelectionAll: [],
      idKey: 'id',
      nextPageFlag: false,
      keyNum: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this2 = this;
      this.selectionList = val;
      setTimeout(function () {
        _this2.changePageCoreRecordData();
      }, 50);
    },
    // 设置选中的方法
    setSelectRow: function setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      // 标识当前行的唯一键的名称
      var idKey = this.idKey;
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      this.$refs.table.clearSelection();
      for (var i = 0; i < this.tableData.data.length; i++) {
        if (selectAllIds.indexOf(this.tableData.data[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.table.toggleRowSelection(this.tableData.data[i], true);
        }
      }
    },
    // 记忆选择核心方法
    changePageCoreRecordData: function changePageCoreRecordData() {
      // 标识当前行的唯一键的名称
      var idKey = this.idKey;
      var that = this;
      // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
      if (this.multipleSelectionAll.length <= 0) {
        this.multipleSelectionAll = this.selectionList;
        return;
      }
      // 总选择里面的key集合
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      var selectIds = [];
      // 获取当前页选中的id
      this.selectionList.forEach(function (row) {
        selectIds.push(row[idKey]);
        // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
        if (selectAllIds.indexOf(row[idKey]) < 0) {
          that.multipleSelectionAll.push(row);
        }
      });
      var noSelectIds = [];
      // 得到当前页没有选中的id
      this.tableData.data.forEach(function (row) {
        if (selectIds.indexOf(row[idKey]) < 0) {
          noSelectIds.push(row[idKey]);
        }
      });
      noSelectIds.forEach(function (id) {
        if (selectAllIds.indexOf(id) >= 0) {
          for (var i = 0; i < that.multipleSelectionAll.length; i++) {
            if (that.multipleSelectionAll[i][idKey] == id) {
              // 如果总选择中有未被选中的，那么就删除这条
              that.multipleSelectionAll.splice(i, 1);
              break;
            }
          }
        }
      });
    },
    add: function add() {
      var _this = this;
      this.$modalAttr(Object.assign({}, this.formDynamic), function () {
        _this.getList();
      }, this.keyNum += 1);
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _store.templateListApi)(this.tableFrom).then(function (res) {
        var list = res.list;
        _this3.tableData.data = list;
        _this3.tableData.total = res.total;
        for (var i = 0; i < list.length; i++) {
          list[i].ruleValue = JSON.parse(list[i].ruleValue);
        }
        _this3.$nextTick(function () {
          this.setSelectRow(); // 调用跨页选中方法
        });
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.changePageCoreRecordData();
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.changePageCoreRecordData();
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        (0, _store.attrDeleteApi)(id).then(function () {
          _this4.$message.success(_i18n.default.t('sccg'));
          _this4.tableData.data.splice(idx, 1);
        });
      }).catch(function () {});
    },
    handleDeleteAll: function handleDeleteAll() {
      var _this5 = this;
      if (!this.multipleSelectionAll.length) return this.$message.warning(_i18n.default.t('qxzspgg'));
      var data = [];
      this.multipleSelectionAll.map(function (item) {
        data.push(item.id);
      });
      this.ids = data.join(',');
      this.$modalSure().then(function () {
        (0, _store.attrDeleteApi)(_this5.ids).then(function () {
          _this5.$message.success(_i18n.default.t('sccg'));
          _this5.getList();
        });
      }).catch(function () {});
    },
    onEdit: function onEdit(val) {
      var _this = this;
      this.$modalAttr(JSON.parse(JSON.stringify(val)), function () {
        _this.getList();
      });
    }
  }
};