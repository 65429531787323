"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function vModel(self, dataObject, defaultValue) {
  dataObject.props.value = defaultValue;
  dataObject.on.input = function (val) {
    self.$emit('input', val);
  };
}
var componentChild = {};
/**
 * 将./slots中的文件挂载到对象componentChild上
 * 文件名为key，对应JSON配置中的__config__.tag
 * 文件内容为value，解析JSON配置中的__slot__
 */
var slotsFiles = require.context('./slots', true, /\.js$/);
var keys = slotsFiles.keys() || [];
keys.forEach(function (key) {
  var tag = key.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = slotsFiles(key).default;
  componentChild[tag] = value;
});
var _default = exports.default = {
  render: function render(h) {
    var _this = this;
    var dataObject = {
      attrs: {},
      props: {},
      on: {},
      style: {}
    };
    var confClone = JSON.parse(JSON.stringify(this.conf));
    var children = [];
    var childObjs = componentChild[confClone.__config__.tag];
    if (childObjs) {
      Object.keys(childObjs).forEach(function (key) {
        var childFunc = childObjs[key];
        if (confClone.__slot__ && confClone.__slot__[key]) {
          children.push(childFunc(h, confClone, key));
        }
      });
    }
    Object.keys(confClone).forEach(function (key) {
      var val = confClone[key];
      if (key === '__vModel__') {
        vModel(_this, dataObject, confClone.__config__.defaultValue);
      } else if (dataObject[key]) {
        dataObject[key] = _objectSpread(_objectSpread({}, dataObject[key]), val);
      } else {
        dataObject.attrs[key] = val;
      }
    });
    delete dataObject.attrs.__config__;
    delete dataObject.attrs.__slot__;
    return h(this.conf.__config__.tag, dataObject, children);
  },
  props: ['conf']
};