"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/uploadPicture/index.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "index",
  data: function data() {
    return {
      pictureType: 'maintain'
    };
  },
  components: {
    UploadIndex: _index.default
  }
};