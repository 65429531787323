"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _baseInfo = _interopRequireDefault(require("./components/baseInfo"));
var _gridMenu = _interopRequireDefault(require("./components/gridMenu"));
var _visitChart = _interopRequireDefault(require("./components/visitChart"));
var _userChart = _interopRequireDefault(require("./components/userChart"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'Dashboard',
  components: {
    baseInfo: _baseInfo.default,
    gridMenu: _gridMenu.default,
    visitChart: _visitChart.default,
    userChart: _userChart.default
  },
  data: function data() {
    return {
      authStatus: null,
      authHost: '',
      authQueryStatus: false
    };
  },
  methods: {
    checkPermi: _permission.checkPermi
  }
};