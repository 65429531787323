"use strict";

var _vue = _interopRequireDefault(require("vue"));
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// svg component

// register globally
_vue.default.component('svg-icon', _SvgIcon.default);
var req = require.context('./svg', false, /\.svg$/);
var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};
requireAll(req);