"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var systemAdminApi = _interopRequireWildcard(require("@/api/systemadmin.js"));
var roleApi = _interopRequireWildcard(require("@/api/role.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "index",
  data: function data() {
    return {
      constants: this.$constants,
      loading: false,
      templateRadio: '',
      dialogFormVisible: false,
      tableData: [],
      artFrom: {
        page: 1,
        limit: 20,
        status: 1,
        realName: '',
        roles: ''
      },
      total: 0,
      timeVal: '',
      roleList: []
    };
  },
  created: function created() {
    this.handleGetRoleList();
  },
  methods: {
    handleGetRoleList: function handleGetRoleList() {
      var _this = this;
      var _pram = {
        page: 1,
        limit: 9999
      };
      roleApi.getRoleList(_pram).then(function (data) {
        _this.roleList = data;
      });
    },
    getTemplateRow: function getTemplateRow(row) {
      this.dialogFormVisible = false;
      this.$emit("upImgUid", row);
    },
    tableList: function tableList() {
      var _this2 = this;
      this.loading = true;
      systemAdminApi.adminList(this.artFrom).then(function (data) {
        _this2.tableData = data.list;
        _this2.total = data.total;
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
      // let that = this;
      // that.loading = true;
      // userListApi(that.artFrom).then(res=>{
      //   that.loading = false;
      //   that.tableData = res.list;
      //   that.total = res.total
      // })
    },
    //切换显示条数
    sizeChange: function sizeChange(index) {
      this.artFrom.limit = index;
      this.tableList();
    },
    //切换页数
    pageChange: function pageChange(index) {
      this.artFrom.page = index;
      this.tableList();
    },
    onchangeTime: function onchangeTime(e) {
      this.artFrom.page = 1;
      if (e !== null) {
        this.artFrom.data = e.join(',');
      } else {
        this.artFrom.data = '';
      }
      this.tableList();
    },
    search: function search() {
      this.timeVal = '';
      this.artFrom.page = 1;
      this.tableList();
    },
    cancel: function cancel() {
      this.artFrom = {
        page: 1,
        limit: 20,
        data: '',
        realName: ''
      };
      this.timeVal = '';
      this.templateRadio = '';
    }
  }
};