export default {
  register: 'register',
  forgetpassword: 'ForgetPassword?',
  Login: 'Login',
  a: 'Домашняя страница',
  ab: 'Индивидуальный центр',
  ac: 'Выход',
  ad: 'Сумма продажи',
  ae: 'сегодн',
  af: 'Данные за вчерашний день',
  ag: 'доллар',
  fwl: 'Количество посетителей',
  ddl: 'Количество заказов',
  dd: 'заказ',
  xzyh: 'Новые пользователи.',
  r: 'люд',
  yhdj: 'Уровень абонента',
  djmc: 'Звание.',
  qsrdjmc: 'Введите, пожалуйста, звание',
  dj: 'иерарх',
  qsrdj: 'Введите уровень, пожалуйста.',
  xszk: 'Скидка.(%)',
  qsrxszk: 'Введите для получения скидки',
  jy: 'опыт',
  qsrjy: 'Введите опыт.',
  tb: 'иконк',
  qx: 'отмен',
  qd: 'увер',
  djbxwsz: 'Иерархия должна быть цифрой',
  qsrzk: 'Введите скидки.',
  jybxwsz: 'Опыт должен иметь цифровое значение',
  qsctb: 'Пожалуйста, загрузите икону',
  qscyhbj: 'Пожалуйста, загрузите данные пользователя',
  bjcg: 'Удачное редактирование.',
  tjcg: 'Добавить успех',
  djtb: 'Ранговая икона',
  zt: 'состоян',
  kq: 'нача',
  gb: 'закр',
  cz: '操作',
  bj: 'операцион',
  sc: 'удал',
  wxyh: 'Пользователь с микроверой',
  xcxyh: 'Небольшая программа для пользователей',
  h5yh: 'Абонент H5',
  deletemsg: 'Удалить? Удаление может привести к очистке соответствующей иерархии данных пользователя！',
  sccg: 'Удаление удалено.',
  xgcg: 'Модификация завершена.',
  updatemsg: 'Эта операция может привести к сокрытию соответствующего пользовательского ранга',
  tjyhdj: 'Добавлять рейтинг пользователя',
  yhss: 'Поиск пользователя',
  yhtz: 'Уведомление пользователя',
  yhbh: 'Номер абонента：',
  yhdz: 'Адрес абонента：',
  yhbz: 'Примечание пользователя：',
  qsrxmhsjh: 'Пожалуйста, введите имя или номер телефона',
  hydj: 'Членство.：',
  qxz: 'Выбирай.',
  sjxz: 'Выбор времени.：',
  zdysj: 'Определение времени',
  fwqk: 'Доступ.：',
  qb: 'все',
  scfw: 'Первый визит',
  sjdfwg: 'Доступ в временные рамки',
  sjdwfw: 'Время не было доступно',
  xb: 'гендерн：',
  wz: 'неизвестн',
  nan: 'мужск',
  nv: 'женщин',
  bm: 'конфиденциальн',
  ss: 'поиск',
  /*
    cz: '重置',
  */
  zk: 'нача',
  sq: 'убер',
  plszhy: 'Оптом назначать членов',
  sf: 'личност：',
  /*
    scfw: '首次访问：',
  */
  jcfw: 'Последний визит：',
  sjh: 'Номер телефона.：',
  bq: 'этикетк：',
  dz: 'адрес：',
  bz: 'примечан：',
  tx: 'голов',
  xm: 'им',
  ye: 'баланс',
  caozuo: 'операцион',
  zhxq: 'Подробности счета.',
  gd: 'больш',
  xgsjh: 'Измени номер телефона.',
  xgyhdj: 'Изменение уровня пользователя',
  yhlb: 'Список пользователей.',
  sz: 'настройк',
  yhfz: 'Группировка пользователей',
  qxzyhfz: 'Пожалуйста, выберите группу пользователей',
  qxzfz: 'Пожалуйста, выберите группу',
  qxzyhbq: 'Пожалуйста, выберите хэштег пользователя',
  yhbq: 'Хэштег пользователя',
  qxzbq: 'Выберите ярлыки.',
  /*
    bj: '编辑',
  */
  jfye: 'Интегральный баланс',
  xgye: 'Модифицировать баланс',
  zj: 'увелич',
  js: 'сокращен',
  xgjf: 'сокращен',
  jf: 'интегральн',
  yhxq: 'Подробности пользователей',
  qbyh: 'Все пользователи.',
  qsrxgsjh: 'Пожалуйста, введите изменение номера телефона',
  qsrsjh: 'Пожалуйста, введите номер телефона',
  qtxsjh: 'Пожалуйста, заполните номер телефона',
  /*
    bjcg: '编辑成功',
  */
  qxsr: 'Отбой.',
  szcg: 'Получилось.',
  qxzyszdyh: 'Пожалуйста, выберите пользователя',
  qxxzyh: 'Пожалуйста, сначала выберите пользователя',
  /*
    sccg: '删除成功',
  */
  sjcg: 'Получилось.',
  xjcg: 'Чисто!',
  qwpfxg: 'Пожалуйста, не меняйте их слишком часто, чтобы не вызвать путаницу в расчетах！',
  kqjy: 'Вычесть опыт',
  zjdd: 'Общий заказ',
  zxfje: 'Суммарная сумма потребления',
  bydd: 'Заказ на месяц.',
  byxfje: 'Сумма расходов за этот месяц',
  ddid: 'заказID',
  shr: 'Получатель.',
  spsl: 'Количество товаров',
  spzj: 'Валовая товарная цена',
  sfje: 'Действительная сумма платежа',
  jywcsj: 'Время сделки',
  wzf: 'Не оплачено.',
  wfh: 'Неотправка.',
  dfh: 'Ждем товар.',
  dpj: 'Ждем товар.',
  dpl: 'Оценка.',
  jywc: 'Сделка завершена.',
  /*
    sjxz: '时间选择：',
  */
  ddh: 'Номер заказа.：',
  qsrddh: 'Введите номер заказа',
  dc: 'Введите номер заказа',
  yhysc: 'Абонент удален.',
  /*
    shr: '收货人',
  */
  spxx: 'Информация о товарах',
  yxzf: 'Платить надо',
  sjzf: 'Фактическая оплата',
  fkpz: 'Квитанция платежа',
  zwsc: 'Пока нет.',
  ddzt: 'Порядок.',
  yscpzqqrsk: 'Пожалуйста, подтвердите получение',
  ywc: 'Готово.',
  xdsj: 'Уже заказал',
  ddxq: 'Подробности приказа',
  qrsk: 'Подтвердить получение',
  fsh: 'Доставка.',
  qrsh: 'Подтвердить получение',
  ljhx: 'Немедленно.',
  ts: 'подсказк',
  qdyhysdhw: 'Убедитесь, что пользователь получил товар?',
  qrzf: 'Подтвердить платеж',
  yhzgpzjt: 'Скриншот сертификата оплаты пользователем',
  yhyzfje: 'Пользователь должен заплатить сумму: ',
  qckyhzfpz: 'Пожалуйста, проверьте платежные документы пользователя и нажмите "подтверждено" после подтверждения！',
  czjl: 'Операционная запись.',
  czsj: 'Время операции',
  jjtkyy: 'Причина отказа от возмещения',
  tkcl: 'Возврат денег',
  hxddm: 'Код заказа на продажу',
  hxcg: 'Успешно.',
  czcg: 'Операция выполнена.',
  /*
    sccg: '删除成功',
  */
  nxzdddczyhwscddd: 'Порядок, который вы выбрали, содержит неудаленные приказы пользователя, которые не могут быть удалены пользователем！',
  /*
    ts: '提示',
  */
  ddbz: 'Внимание',
  qsrddbz: 'Введите примечание',
  srbnwk: 'Ввод не может быть пустым',
  /*
    qxsr: '取消输入',
  */
  qrcg: 'Убедиться в успехе',
  bjsjcg: 'Удачное редактирование данных',
  ddsl: 'Количество заказов',
  ddje: 'Сумма заказа',
  dycg: 'Печать выполнена.',
  xzlx: 'Выбирайте.：',
  sh: 'доставк',
  fhlx: 'Тип поставки：',
  sdtx: 'Заполнять вручную',
  dzmddy: 'Электронно-обратная печать',
  shrxm: 'Имя отправителя：',
  qsrshrxm: 'Введите имя отправителя',
  shrdh: 'Телефон доставщика：',
  qsrshrdh: 'Пожалуйста, введите номер отправителя',
  tj: 'представ',
  /*
    qtxsjh: '请填写手机号',
  */
  sjhgsbzq: 'Номер телефона неправильный!',
  fshcg: 'Доставка прошла успешно.',
  qtxxx: 'Заполните, пожалуйста.',
  ddxx: 'Приказ.',
  yhxx: 'Информация пользователей.',
  yhnc: 'Ник пользователя',
  bddh: 'Связывающий телефон',
  ddbh: 'Номер заказа',
  /*
    ddzt: '订单状态',
  */
  spzs: 'Товарный фонд',
  /*
    spzj: '商品总价',
  */
  /*
    sjzf: '实际支付',
  */
  cjsj: 'Создание времени',
  tksj: 'Время возмещения.',
  zffs: 'Способ оплаты',
  mdmc: 'Название магазина',
  hxm: 'Коды.：',
  psxx: 'Распределять.',
  /*
    shrxm: '送货人姓名：',
  */
  /*
    shrdh: '送货人电话：',
  */
  /*
    yhbz: '用户备注',
  */
  sjbz: 'Внимание бизнесменам：',
  /*
    fsh: '发送货',
  */
  kdgs: 'Служба доставки.：',
  kddh: 'Номер посылки：',
  qsrkddh: 'Введите номер доставки',
  zzjzz: 'загружается',
  sljzgd: 'Загружай больше',
  xxsj: 'Подробные данные',
  rq: 'дат',
  dds: 'Стационарное число',
  cje: 'Обмен.',
  ck: 'провер',
  qdyhxcddm: '确定要核销此订单吗？',
  qdhx: 'Устанавливать, установить учет',
  dfk: 'Оплата.',
  tk: 'компенсац',
  sjtj: 'Статистика данных',
  jrcje: 'Сегодня сделка',
  zrcje: 'Вчера был обмен',
  bycje: 'Плата за этот месяц',
  jrdds: 'Закажите на сегодня',
  zrdds: 'Заказал только вчера',
  bydds: 'Закажите в этом месяце',
  fhfs: 'Способ доставки',
  songhr: 'Курьер.',
  txshr: 'Заполните форму доставщика',
  txshdh: 'Заполнять телефон доставки',
  qrtj: 'Подтверждаю.',
  shdh: 'Телефон доставки.',
  fh: 'доставк',
  /*
    sh: '送货',
  */
  wxfh: 'Груз не нужен.',
  g: 'на',
  jsp: 'Товар.，Должно быть оплачено.',
  yf: 'почтов ¥',
  /*
    ddbh: '订单编号：',
  */
  fz: 'скопирова',
  zfzt: 'Платежный режим：',
  /*
    zffs: '支付方式：',
  */
  mjly: 'Сообщение от покупателя.：',
  zfje: 'Сумма выплаты：',
  /*
    yf: '运费：',
  */
  sfk: 'Настоящий платеж.：',
  psfs: 'Способ распределения：',
  kd: 'доставк',
  /*
    kddh: '快递单号',
  */
  songhrdh: 'Телефон доставщика',
  yjgj: 'Одна кнопка на изменение цены',
  ljtk: 'Немедленно верните деньги.',
  /*
    ddbz: '订单备注',
  */
  qfh: 'доставк',
  qhx: 'занима',
  /*
    tk: '退款',
  */
  /*
    ywc: '已完成',
  */
  jjtk: 'Возврат денег отклонен.',
  zwsj: 'Приходящее множество доказательств',
  yjjtk: 'Возврат денег отклонен.',
  tjiaocg: 'Представлять успех',
  jt: 'jt',
  zuotian: 'вчер',
  zj7t: 'Последние семь дней.',
  by: 'месяц',
  zdy: 'Самоопределение.',
  zengzhang: 'Рост',
  zengzhanglv: 'Рост',
  zy: 'В понедельник',
  ze: 'Во вторник',
  zs: 'сред',
  zsi: 'В четверг',
  zw: 'В пятниц',
  zl: 'В суббот',
  zr: 'В воскресен',
  /*
    spxx: '商品信息',
  */
  spxq: 'Детали товара',
  qtsz: 'Другие настройки.',
  spmc: 'Название товара:',
  qsrspmc: 'Введите название товара',
  spfl: 'Классификация товаров：',
  /*
    qxz: '请选择',
  */
  danwei: 'подразделен：',
  qsrdw: 'Введите единицу.',
  spjj: 'Справочник товаров：',
  qsrspjj: 'Пожалуйста, введите профиль товара',
  spfmt: 'Схема обложки товара：',
  splbt: 'Диаграмма товарного колеса：',
  spgg: 'Товарный спецификация：',
  dgg: 'Монограмма.',
  duogg: 'Многослойность.',
  xzgg: 'Спецификация отбора：',
  tjgg: 'Добавьте спецификации.',
  tianjia: 'добавля',
  guige: 'спецификац：',
  qsrgg: 'Введите спецификации.',
  ggz: 'Спецификация.：',
  qsrggz: 'Введите спецификации, пожалуйста',
  tjxgg: 'Добавлять новые спецификации',
  plsz: 'Оптовый установка：',
  tp: 'картинк',
  qr: 'подтверд',
  /*
    cz: '操作',
  */
  pltj: 'Массовое добавление',
  qbsku: 'все sku：',
  zanshi: 'показа',
  spsx: 'Товарный свойство：',
  paixu: 'сортировк：',
  qsrpx: 'Пожалуйста, введите последовательность',
  xnxl: 'Виртуальные продажи.：',
  qsrxnxl: '请输入虚拟销量',
  syb: 'Шаг вперед.',
  xyb: 'Следующий шаг.',
  tijiao: 'представ',
  soujia: 'сто',
  vipsoujia: 'VIP сто',
  kc: 'инвентаризац',
  spbh: 'Номер товара',
  zliang: 'вес（KG）',
  tiji: 'объём(m³)',
  /*
    qsrspmc: '请输入商品名称',
  */
  qxzspfl: 'Пожалуйста, выберите категорию товаров',
  qsrspgjz: 'Введите ключевое слово',
  /*
    qsrdw: '请输入单位',
  */
  qscspt: 'Пожалуйста, загрузите карту товаров',
  qscsplbt: 'Пожалуйста, загрузите диаграмму товарного колеса',
  qxzspgg: 'Пожалуйста, выберите товары',
  qxzsx: 'Выберите атрибуты.',
  qtjwzdgg: 'Пожалуйста, добавьте полную спецификацию！',
  qtjsx: 'Пожалуйста, добавьте атрибуты.',
  zdxz: 'Выберите максимум 10 фотографий！',
  qtxwzspxx: 'Заполните полную информацию о товарах！',
  qtxdggsx: 'Пожалуйста, заполните политехнические характеристики！',
  sfrm: 'Горячо?',
  ggss: 'Поиск по спецификации：',
  qsrspgg: 'Введите спецификации товара',
  tjspgg: 'Добавлять спецификации товара',
  plsc: 'Массовое удаление',
  ggmc: 'Спецификация.',
  /*  spgg: '商品规格',
    spsx: '商品属性',
    sccg: '删除成功',
    qxzspgg: '请选择商品规格',
    spfl: '商品分类：',*/
  spss: 'Поиск товаров：',
  qsrspmcgjz: 'Введите название товара，Ключевое слово.，товарID',
  tjsp: 'Добавьте товар.',
  /*
    dc: '导出',
  */
  /*
    spfl: '商品分类：',
  */
  scj: 'Рыночная цена：',
  cbj: 'Себестоимость.：',
  /*
    sc: '收藏：',
  */
  /*
    xnxl: '虚拟销量：',
  */
  spt: 'Карта товаров',
  /*
    spmc: '商品名称',
  */
  spsj: 'Цена на товар',
  vipsj: 'VIP сто',
  xl: 'продаж',
  /*
    kc: '库存',
  */
  px: 'сортировк',
  tjsj: 'Добавьте время.',
  sj: 'продаж',
  xj: 'С полок',
  xq: 'подробн',
  hfsp: 'Вернуть товар',
  /*
    czcg: '操作成功',
  */
  scid: 'удал id эт',
  wzfl: 'Классификация статей：',
  qxzwzfl: 'Пожалуйста, выберите сортировку статей',
  gjc: 'Ключевое слово.：',
  qsrgjc: 'Введите ключевое слово',
  tjwz: 'Добавить статью',
  bt: 'заголовк',
  /*
    wzfl: '文章分类',
  */
  lll: 'Количество просмотров.',
  zz: 'автор',
  gxsj: 'Время обновления.',
  wzjj: 'Профиль статьи',
  /*
    bj: '编辑',
  */
  qdscdqsj: 'Определить удаление текущих данных',
  sanchu: 'удал',
  /*
    bt: '标题',
  */
  zuozhe: 'автор',
  /*
    wzfl: '文章分类',
  */
  /*
    qxz: '请选择',
  */
  twfm: 'Обложка.',
  /*
    wzjj: '文章简介',
  */
  wznr: 'Содержание статьи',
  isb: 'Не так ли?Banner',
  ishot: 'Горячий?',
  bc: 'сохран',
  qtxbt: 'Пожалуйста, заполните документы.',
  /*
    fh: '返回',
  */
  gifly: 'gif источник',
  ym: 'страниц',
  nmyqx: 'У вас нет допуска к этой странице',
  ysmwt: 'Если у вас есть претензии, обратитесь к вашему лидеру',
  hznky: 'Или ты можешь уйти:',
  hsy: 'На первую страницу.',
  sbkk: 'Осмотритесь.',
  dwkt: 'Покажи мне картинку',
  qjcqjc: 'Пожалуйста, проверьте, правильно ли введены URL, или нажмите кнопку внизу, чтобы вернуться на страницу.',
  fhkzt: 'Вернитесь на пульт.',
  hygl: 'Управление членством',
  xtsz: 'Настройка системы.',
  sp: 'товар',
  ddgl: 'Управление заказом',
  skzh: 'Счет сбора средств',
  yhtj: 'Статистика пользователей',
  rsr: 'Число (число)',
  ddtj: 'Учет заказа',
  sst: '30 дне',
  zou: 'недел',
  yue: 'месяц',
  nian: 'год',
  ddqs: 'Тенденция заказа',
  je: 'сумм',
  sl: 'количеств',
  /*
    ddje: '订单金额',
  */
  /*
    dds: '订单数',
  */
  szje: 'На прошлой неделе.',
  bzje: 'На этой неделе.',
  szdds: 'Забронировал на прошлой неделе',
  bzdds: 'Закажите на этой неделе',
  yhm: 'Имя пользователя.',
  mima: 'код',
  yzm: 'Код подтверждения.',
  dl: 'залогин',
  mmws: 'Код - 6-12 цифр',
  qsryhm: 'Введите имя пользователя',
  qsrmm: 'Введите пароль.',
  qsrzqdyzm: 'Введите правильный код веридан',
  yhkh: 'Номер банковской карточки',
  yhkmc: 'Имя карточки',
  sfzs: 'Показать?',
  /*
    bz: '备注',
  */
  tpdz: 'Адрес фотографии',
  qsryhkh: 'Введите номер банковской карточки',
  qsrkmc: 'Введите имя карточки',
  qsrbz: 'Пожалуйста, введите примечание',
  qsctp: 'Загрузите изображение.以上翻译结果来自有道神经网络翻译（YNMT）· 通用场景\n',
  cxcs: 'Параметр запроса',
  cx: 'запрос',
  xzsj: 'Новые данные',
  /*
    plsc: '批量删除',
  */
  /*
    cjsj: '创建时间',
  */
  cjr: 'Создатель.',
  xgsj: 'Время на изменение.',
  xgr: 'Модификатор.',
  jrhsz: 'Присоединяйся к утилизации'
};